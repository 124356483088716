import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { lazy, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loadable from '@components/Loading/Loadable';
import PublicRoute from '@routes/PublicRoute';
import PrivateRoute from '@routes/PrivateRoute';
import { Login } from 'pages-client/Auth';
import MainLayout from '@layout/MainLayout';
import TotalOrderHistory from 'pages-client/TotalOrderHistory';
import { StatisticsByMemberPage } from 'pages-client/statistics';
import useResponsive from '@hooks/useResponsive';
import { Account } from 'pages-client/Account';
import NotFound from '@pages/NotFound';
import MainPage from '@pages/Main';
import LayoutNew from '@layout/new/LayoutNew';
import LayoutTransparent from '@layout/new/LayoutTransparent';
// import AboutUs from '@pages/AboutUs';
// import Offresh2 from '@pages/Offesh2';
// import Layout from './layout';

// import MediaPage from '@pages/Media';

// Lazy loaded components
const Spot = Loadable(lazy(() => import('@pages/Services/Spot')));
const OffreshLazy = Loadable(lazy(() => import('@pages/Services/Offresh')));
const MissionPage = Loadable(lazy(() => import('@pages/Mission')));
const MediaPage = Loadable(lazy(() => import('@pages/Media')));
const WorkershighMedia = Loadable(
  lazy(() => import('@pages/WorkershighMedia'))
);
const PortfolioPage = Loadable(lazy(() => import('@pages/Portfolio')));
const PortfolioDetail = Loadable(
  lazy(() => import('@pages/Portfolio/Components/PortfolioDetail'))
);
const SupportPage = Loadable(
  lazy(() => import('@pages/CustomerSupport/AnnouncementsPage'))
);
const AnnouncementDetailPage = Loadable(
  lazy(() => import('@pages/CustomerSupport/AnnouncementDetail'))
);
const FAQ = Loadable(lazy(() => import('@pages/CustomerSupport/FAQ')));
const CareerPage = Loadable(lazy(() => import('@pages/Career')));
const CareerDetailPage = Loadable(
  lazy(() => import('@pages/Career/CareerDetail'))
);
const ApplicationPage = Loadable(
  lazy(() => import('@pages/Career/ApplicationPage'))
);

// const LayoutNew = Loadable(lazy(() => import('@layout/new')));
function App() {
  const isSm = useResponsive('down', 1000);
  useEffect(() => {
    AOS.init({ duration: 500 });
    AOS.refresh();
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      AOS.init({ duration: 500 });
      AOS.refresh();
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <Router
      render={({ location }) => {
        window.gtag('config', 'G-YNFZWPGHSM', {
          page_path: location.pathname + location.search
        });
      }}
    >
      <Routes>
        {/* <Route path="/" element={<Layout />}>
          <Route path="" element={<AboutUs />} />
          <Route path="offresh" element={<Offresh2 />} />
        </Route> */}
        {/* <Route path="/en" element={<Layout />}>
          <Route path="" element={<AboutUs />} />
          <Route path="offresh" element={<Offresh2 />} />
        </Route> */}

        <Route path="/" element={<LayoutNew />}>
          <Route
            path="company-introduction/mission"
            element={<MissionPage />}
          />
          <Route path="company-introduction/media" element={<MediaPage />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="portfolio">
            <Route index path="" element={<PortfolioPage />} />
            <Route path=":portfolioItemId" element={<PortfolioDetail />} />
          </Route>
          <Route path="customer-support">
            <Route path="notices">
              <Route index path="" element={<SupportPage />} />
              <Route
                exact
                path=":noticeCode"
                element={<AnnouncementDetailPage />}
              />
            </Route>
            <Route path="faq" element={<FAQ />} />
          </Route>
          <Route path="careers">
            <Route index path="" element={<CareerPage />} />
            <Route
              exact
              path="recruit/:recruitCode"
              element={<CareerDetailPage />}
            />
            <Route
              exact
              path="recruit/:recruitCode/application"
              element={<ApplicationPage />}
            />
          </Route>
        </Route>
        <Route path="/" element={<LayoutTransparent />}>
          <Route index path="" element={<MainPage />} />

          <Route path="service">
            <Route index path="" element={<OffreshLazy />} />
            <Route exact path="offresh" element={<OffreshLazy />} />
            <Route exact path="WorkersMarket" element={<Spot />} />
            <Route path="media" element={<WorkershighMedia />} />
          </Route>
        </Route>

        {/* Client admin routes */}
        <Route path="" element={<PublicRoute />}>
          <Route path="/admin" element={<Login />} />
        </Route>
        <Route path="" element={<PrivateRoute />}>
          <Route path="/" element={<MainLayout />}>
            <Route
              path="/statistics/by-member"
              element={<StatisticsByMemberPage />}
            />
            <Route
              path="/total-order-history"
              element={<TotalOrderHistory />}
            />
            <Route path="/account" element={<Account />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
