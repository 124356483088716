// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          },
          whiteSpace: 'nowrap',
          borderRadius: '100px',
          textTransform: 'none',
          padding: '14px 20px'
        },
        sizeMedium: {
          height: 44
        },
        sizeLarge: {
          height: 48
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.main,
          // boxShadow: theme.customShadows.primary,
          '&:hover': {
            backgroundColor: theme.palette.primary.darker
          }
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        outlinedPrimary: {
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          boxShadow: theme.customShadows.primary,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
          }
        },
        textPrimary: {
          backgroundColor: theme.palette.primary.lighter,
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.darker
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    }
  };
}
