import { Radio, styled } from '@mui/material';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  border: '1px solid #DEE2E6', // Changed border to '2px solid grey'
  width: 20,
  height: 20,
  backgroundColor: '#FFFFFF',

  // Add stroke customization for the unchecked icon
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%', // Center the pseudo-element vertically
    left: '50%', // Center the pseudo-element horizontally
    transform: 'translate(-50%, -50%)', // Ensure the centering works correctly
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#FFFFFF'
    // border: '2px solid grey' // Changed border to '2px solid grey'
  }
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#244EE2',
  borderRadius: '50%',
  border: 0,
  width: 20,
  height: 20,
  // backgroundImage:
  //   'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    // backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: '#244EE2'
  }
});

export function MyRadio(props) {
  return (
    <Radio
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
