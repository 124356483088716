import { Stack } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loadable from '@components/Loading/Loadable';
import React, { lazy, useEffect } from 'react';
import './index.css';
import ScrollTop from '@components/buttons/ScrollTop';

const Banner = Loadable(lazy(() => import('./Banner')));
const Result = Loadable(lazy(() => import('./Result')));
const ClientSlider = Loadable(lazy(() => import('./ClientSlider')));
const Reviews = Loadable(lazy(() => import('./Reviews.jsx')));
const OurBenefit = Loadable(lazy(() => import('./OurBenefit')));
const FeatureHighlights = Loadable(lazy(() => import('./FeatureHighlights')));
const Process = Loadable(lazy(() => import('./Process')));
const ServiceSection = Loadable(lazy(() => import('./ServiceSection')));
const AdCampaign = Loadable(lazy(() => import('../components/AdCampaign')));
const Product = Loadable(lazy(() => import('./Product')));
const FAQ = Loadable(lazy(() => import('./FAQ')));
const EquipmentLine = Loadable(lazy(() => import('./EquipmentLine')));
const QuickActions = Loadable(
  lazy(() => import('@layout/Footer/QuickActions'))
);

const MainPage = () => {
  AOS.init({
    startEvent: 'load'
  });
  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, []);

  return (
    <Stack
      sx={{
        width: '100%',
        maxWidth: '100%',

        alignItems: 'center'
      }}
    >
      <Banner />
      <Result />
      <ClientSlider />
      <Reviews />
      <OurBenefit />
      <FeatureHighlights />
      <Process />
      <ServiceSection />
      <AdCampaign />
      <Product />
      <EquipmentLine />
      <FAQ />
      <QuickActions />
      <ScrollTop theme="light" />
      {/* <ChannelTalkButton /> */}
    </Stack>
  );
};

export default MainPage;
