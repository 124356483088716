import React from 'react';
import { Box, Snackbar } from '@mui/material';
import { nanoid } from 'nanoid';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
import useResponsive from '@hooks/useResponsive';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MySnackbar = ({
  open,
  onClose,
  message,
  placement = { vertical: 'bottom', horizontal: 'center' }
}) => {
  const isSm = useResponsive('down', 1024);
  return (
    <Box sx={{ width: 500, zIndex: 50000 }}>
      <Snackbar
        anchorOrigin={
          isSm ? { vertical: 'top', horizontal: 'center' } : placement
        }
        open={open}
        TransitionComponent={Slide}
        autoHideDuration={2000}
        onClose={() => onClose(false)} // Close Snackbar automatically after duration
        key={nanoid()}
      >
        <Alert
          onClose={() => onClose(false)} // Allow manual close by the user
          severity="success"
          icon={false}
          sx={{
            width: '100%',
            backgroundColor: '#ffff',
            borderRadius: 0,
            color: '#373737',
            borderLeft: '3px solid #244EE2'
          }}
        >
          {message || 'This is a success message!'}{' '}
          {/* Use dynamic message prop or default message */}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MySnackbar;
