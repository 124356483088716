import { Stack, TextField, Typography, Box } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import { isNumber } from '@utils/helpers';

const lang = localStorage.getItem('i18nextLng')?.toLowerCase() || 'ko';

const MyTextField = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  errMg,
  fullWidth,
  required,
  endComponent,
  disabled = false,
  disableRequiredChip = false,
  onKeyDown,
  labelStyle,
  typeValue,
  sx,
  multiline,
  ...rest
}) => {
  const endComponentRef = useRef();
  const [endComponentWidth, setEndComponentWidth] = useState(0);

  useEffect(() => {
    if (endComponentRef.current) {
      setEndComponentWidth(endComponentRef.current.offsetWidth);
    }
  }, [endComponent]);

  return (
    <Stack
      width={fullWidth && '100%'}
      sx={{
        flexDirection: { md: 'column', xs: 'column' },
        alignItems: { md: 'baseline' },
        gap: 1.5
      }}
    >
      {label && (
        <Typography
          width={fullWidth && '100%'}
          sx={{
            fontSize: { md: lang === 'ko' ? 14 : 12, xs: 14 },
            color: '#212121',
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'row',
            gap: 0.5,
            whiteSpace: lang === 'ko' ? 'nowrap' : 'wrap',
            ...labelStyle
          }}
        >
          {label}
          {required && !disableRequiredChip && (
            <Stack
              sx={{
                backgroundColor: '#FF6565',
                width: '4px',
                height: '4px',
                borderRadius: '50%'
              }}
            />
          )}
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          position: 'relative'
        }}
      >
        <TextField
          disabled={disabled}
          error={errMg}
          onPaste={(e) => {
            const value = e.clipboardData.getData('text');
            if (onKeyDown) {
              if (!isNumber(value.replace(/,/g, ''))) {
                e.preventDefault();
              }
            }
          }}
          onKeyDown={onKeyDown}
          autoComplete="off"
          name={name}
          value={value}
          multiline={multiline}
          onChange={(e) => {
            if (typeValue === 'onlyNumber') {
              const value = e.target.value.replace(/\D/g, '');
              e.target.value = value;
              onChange(e);
            } else {
              onChange(e);
            }
          }}
          placeholder={placeholder}
          sx={{
            width: '100%',
            '& .MuiInputBase-root': {
              borderRadius: 0,
              borderColor: '#DEE2E6',
              display: 'flex',
              alignItems: 'center'
            },
            '.MuiInputBase-input': {
              p: !multiline && {
                md: '10px 12px 10px 12px',
                xs: '10px 28px 10px 12px'
              },
              color: '#212121',
              fontSize: {
                md: 14,
                xs: 12
              },
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: endComponent
                ? `calc(100% - ${endComponentWidth + 24}px)`
                : '100%'
            },
            cursor: 'text',
            ...sx
          }}
          {...rest}
        />
        {endComponent && (
          <Box
            sx={{
              position: 'absolute',
              right: '12px',
              display: 'flex',
              alignItems: 'center',
              pointerEvents: 'none'
            }}
            ref={endComponentRef}
          >
            {endComponent}
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default MyTextField;
