import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  errorLP: null,
  error: null,
  info: null,
  onOk: null,
  success: null,
  lang: localStorage.getItem('i18nextLng')?.toLowerCase(),
  rerender: false,
  openInquiry: false,
  openForm: false,
  stickyNavbar: true
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setErrorLP: (state, { payload }) => {
      state.error = payload;
    },
    setInfo: (state, { payload }) => {
      state.info = payload;
    },
    setOnOk: (state, { payload }) => {
      state.onOk = payload;
    },
    setLang: (state, { payload }) => {
      state.lang = payload;
    },
    setRerender: (state, { payload }) => {
      state.rerender = payload;
    },
    setSuccess: (state, { payload }) => {
      state.success = payload;
    },
    setInquiry: (state, { payload }) => {
      state.openInquiry = payload;
    },
    setOpenForm: (state, { payload }) => {
      state.openForm = payload;
    },
    setStickyNavbar: (state, { payload }) => {
      state.stickyNavbar = payload;
    }
  }
});

export const {
  setLoading,
  setError,
  setInfo,
  setOnOk,
  setLang,
  setRerender,
  setInquiry,
  setSuccess,
  setErrorLP,
  setOpenForm,
  setStickyNavbar
} = appSlice.actions;

export default appSlice.reducer;
