/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
import React, { useState } from 'react';

import { ReactComponent as Chat } from 'images/icon/token_chat.svg';
import { ReactComponent as Call } from 'images/icon/call.svg';
import { ReactComponent as Mail } from 'images/icon/mail.svg';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import i18n from '@utils/i18n';
import MyDialog from '@components/MyDialog/NewDialog';
import AlertDialog from '@components/MyDialog/AlertDialog';
import MyTextField from '@components/MyTextField/NewTextField';

import { isEmail, isValidKoreanPhoneNumber } from '@utils/boolean';

import consultationInquiryService from '@services/consultationInquiry';

import { useSelector } from 'react-redux';
import { MyCheckbox } from '@components/MyCheckbox';
import { useChannelIOApi } from 'react-channel-plugin';

const contactT = i18n.getFixedT(null, null, 'pages.contactForm');
const g = i18n.getFixedT(null, null, '');

const lang = localStorage.getItem('i18nextLng')?.toLowerCase() || 'ko';

const ServiceInquiryForm = (props) => {
  const { closeModal } = props;
  const { showMessenger } = useChannelIOApi();
  const requiredFields = ['companyName', 'picName', 'phone', 'email'];

  // Example usage
  const { openForm } = useSelector((state) => state.app);

  const [inquiryData, setInquiryData] = useState({
    companyName: '',
    picName: '',
    phone: '',
    email: ''
  });
  const [errMsg, setErrMsg] = useState({
    companyName: '',
    picName: '',
    phone: '',
    email: ''
  });

  const [accordion, setAccordion] = useState('none');
  const [accept, setAccept] = useState(false);

  const [nofityDialog, setNofityDialog] = useState(undefined);
  const [successOpen, setSuccessOpen] = useState(false);

  const handleChange = (name, value) => {
    setInquiryData({
      ...inquiryData,
      [name]: value
    });
    if (requiredFields.includes(name))
      setErrMsg((prev) => ({
        ...prev,
        [name]: value ? '' : g('common.required')
      }));
  };

  const handleCheckAccept = (e) => {
    setAccept(e.target.checked);
  };

  const checkValidation = () => {
    // eslint-disable-next-line prefer-const
    let isValid = true;

    requiredFields.forEach((field) => {
      if (!inquiryData[field]) {
        isValid = false;
      }
    });

    // eslint-disable-next-line prefer-rest-params

    return isValid;
  };
  const handleValidateForm = async () => {
    // eslint-disable-next-line prefer-const
    let isValid = true;
    const err = {};
    requiredFields.forEach((field) => {
      if (!inquiryData[field]) {
        isValid = false;
        err[field] = g('common.required');
      } else if (
        field === 'phone' &&
        !isValidKoreanPhoneNumber(inquiryData[field])
      ) {
        isValid = false;
        err[field] = g('pages.career.error.phone-invalid');
      } else if (field === 'email' && !isEmail(inquiryData[field])) {
        isValid = false;
        err[field] = g('pages.career.error.email-invalid');
      }
    });
    Object.values(errMsg).forEach((error) => {
      if (error) {
        isValid = false;
      }
    });
    // eslint-disable-next-line prefer-rest-params

    if (isValid) {
      await handleCreateInquiry();
    } else {
      setErrMsg({ ...err });
    }
  };

  const handleCreateInquiry = async () => {
    const data = {
      entryType: openForm?.type,
      companyName: inquiryData.companyName,
      picName: inquiryData.picName,
      picPhone: inquiryData.phone,
      picEmail: inquiryData.email,
      hasAgreedTerms: accept ? 'Y' : 'N'
    };

    const res = await consultationInquiryService.createServiceIntroInquiry(
      data
    );
    if (!res.error) {
      setNofityDialog(undefined);
      setSuccessOpen(true);
    } else {
      setNofityDialog({
        title: res.error?.message
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3.5 }}>
      {/* companyName */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <MyTextField
          required
          fullWidth
          label={contactT('form.companyName')}
          placeholder={contactT('form.companyPlaceholder')}
          errMg={errMsg.companyName}
          value={inquiryData.companyName}
          onChange={(e) => handleChange('companyName', e.target.value)}
          onClear={() => handleChange('companyName', '')}
        />
        {errMsg.companyName && (
          <Typography
            sx={{
              fontSize: { md: 13, xs: 12 },
              color: '#FF6565'
            }}
          >
            {errMsg.companyName}
          </Typography>
        )}
      </Stack>

      {/* picName */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <MyTextField
          required
          fullWidth
          errMg={errMsg.picName}
          label={contactT('form.pic')}
          placeholder={contactT('form.picPlaceholder')}
          value={inquiryData.picName}
          onChange={(e) => handleChange('picName', e.target.value)}
          onClear={() => handleChange('picName', '')}
        />
        {errMsg.picName && (
          <Typography
            sx={{
              fontSize: { md: 13, xs: 12 },
              color: '#FF6565'
            }}
          >
            {errMsg.picName}
          </Typography>
        )}
      </Stack>

      {/* phone */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <MyTextField
          required
          fullWidth
          label={contactT('form.phoneNumber')}
          placeholder={contactT('form.contactPlaceholder')}
          value={inquiryData.phone}
          // onChange={(e) => handleChange('phone', e.target.value)}
          onChange={(e) => {
            handleChange('phone', e.target.value.replace(/\D/g, ''));
          }}
          onClear={() => handleChange('phone', '')}
          // onKeyDown={onlyNumber}
          errMg={errMsg.phone}
        />
        {errMsg.phone && (
          <Typography
            sx={{
              fontSize: { md: 13, xs: 12 },
              color: '#FF6565'
            }}
          >
            {errMsg.phone}
          </Typography>
        )}
      </Stack>

      {/* Email */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <MyTextField
          required
          errMg={errMsg.email}
          fullWidth
          label={contactT('form.email')}
          placeholder={contactT('form.emailPlaceholder')}
          value={inquiryData.email}
          onChange={(e) => handleChange('email', e.target.value)}
          onClear={() => handleChange('email', '')}
        />
        {errMsg.email && (
          <Typography
            sx={{
              fontSize: { md: 13, xs: 12 },
              color: '#FF6565'
            }}
          >
            {errMsg.email}
          </Typography>
        )}
      </Stack>

      {/* Terms of service */}
      <Stack
        sx={{
          width: '100%'
        }}
      >
        <Stack
          sx={{
            flexDirection: { md: 'row', xs: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack direction="row" alignItems="center">
            <MyCheckbox
              checked={accept}
              onChange={handleCheckAccept}
              sx={{
                mr: { md: '12px' }
              }}
            />
            <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <Typography
                sx={{
                  fontSize: { md: lang === 'ko' ? 14 : 12, xs: 14 },
                  color: '#212121',
                  fontWeight: 600,

                  whiteSpace: 'noWrap'
                }}
              >
                {contactT('form.agreeLabel')}
              </Typography>
              <Stack
                sx={{
                  backgroundColor: '#FF6565',
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%'
                }}
              />
            </Stack>
          </Stack>
          <Stack
            onClick={() => setAccordion(accordion === 'none' ? true : 'none')}
            sx={{
              flexDirection: 'row',
              color: '#919193',
              fontSize: { md: 14, xs: 14 },
              fontWeight: 500,
              textDecoration: 'underline',
              mt: { md: 0, xs: 0 },
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            {accordion === 'none'
              ? contactT('form.detail-open')
              : contactT('form.detail-close')}
          </Stack>
        </Stack>
        <Stack
          display={accordion}
          sx={{
            fontSize: { md: 12, xs: 12, color: '#919193' },
            width: '100%',
            pr: '24px',
            mt: '30px',
            ml: '28px',
            whiteSpace: 'pre-line'
          }}
        >
          {contactT('form.privacyPolicy')}
        </Stack>
      </Stack>
      {/* Action Buttons */}
      <Stack
        sx={{
          width: '100%',
          mt: { md: '12px' }
        }}
      >
        <Button
          id="script-3"
          variant="contained"
          disabled={!accept || !checkValidation()}
          onClick={() => handleValidateForm()}
          sx={{
            height: { md: 40, xs: 40 },
            width: { md: '100%', xs: '100%' },
            fontSize: { md: 16, xs: 12 },
            backgroundColor: '#244EE2',
            color: '#FFFFFF',
            p: '10px 16px',
            fontWeight: 500,
            ':hover': {
              backgroundColor: { md: '#1D3FB7', xs: '#244EE2' },
              color: '#FFFFFF'
            },
            borderRadius: 0,
            '&.Mui-disabled': {
              backgroundColor: '#B2BEEB',
              color: '#FFFFFF'
            }
          }}
        >
          {contactT(`form.button.${openForm?.type}`)}
        </Button>
      </Stack>

      {/* info section */}
      <Stack
        sx={{
          width: '100%',
          mt: 0.5,
          py: '20px',
          borderTop: '1px solid #DEE2E6',
          borderBottom: '1px solid #DEE2E6'
        }}
      >
        <Grid container rowSpacing={3.5}>
          {[
            {
              icon: <Chat />,
              text: '1:1 채팅하기',
              action: () => showMessenger()
            },
            { icon: <Call />, text: '02-2038-3679', href: 'tel:02-2038-3679' },
            {
              icon: <Mail />,
              text: 'service@workershigh.com',
              href: 'mailto:service@workershigh.com'
            }
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={`${item.text}-${index}`}>
              <Stack
                component={item.href ? 'a' : 'div'}
                href={item.href}
                onClick={item.action}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: { md: 'center', xs: 'center' },
                  cursor: 'pointer',
                  textDecoration: 'none', // ensure no default underline for links
                  '&:hover .typography-underline': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {item?.icon}

                <Typography
                  className="typography-underline"
                  sx={{
                    fontSize: { md: 14 },
                    fontWeight: 500,
                    whiteSpace: 'nowrap',

                    letterSpacing: '-0.42px',
                    fontFamily: 'Pretendard',
                    color: '#919193',
                    position: 'relative'
                  }}
                >
                  {item?.text}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
      {/* Notify Dialog */}
      <MyDialog
        isAlert
        open={nofityDialog !== undefined}
        setOpen={() => setNofityDialog(undefined)}
        title={nofityDialog?.title}
        content={nofityDialog?.content}
        onOk={nofityDialog?.onOK}
        onClose={nofityDialog?.onClose}
        hasCancelButton={nofityDialog?.hasCancelButton}
        {...nofityDialog}
      />
      <AlertDialog
        open={successOpen}
        sx={{ minWidth: '335px' }}
        isAlert
        title={contactT(`form.success.${openForm?.type}-title`)}
        subTitle={contactT(`form.success.${openForm?.type}-subtitle`)}
        setOpen={() => setSuccessOpen(false)}
        onOk={() => {
          closeModal();
          setTimeout(() => {
            setSuccessOpen(false);
          }, 100);
        }}
      />
    </Box>
  );
};

export default ServiceInquiryForm;
