import HelmetContainer from '@components/HelmetContainer';
import { MinibarAutoCompleteV2 } from '@components/MyAutocomplete';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import SearchBar from '@components/SearchBar';
import { dateFormat } from '@constants/utils';
import DateRangePicker from '@components/DateRangePicker';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formatter } from '@utils/formatter';

import MyDialog from '@components/MyDialog';
import totalOrderService from '@services/totalOrder';
import GroupTableCustomize from '@components/MyTable/GroupTableCustomize';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import MyLoadingButton from '@components/MyLoadingButton';
import {
  formatMoney,
  ignoreEmpty,
  objToParams,
  paramsToObj
} from '@utils/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentDetails from './components/PaymentDetails';
import ReceiptIssuance from './components/ReceiptIssuance';
import {
  columnTotalOrderHistory,
  searchOptions,
  radioOptions
} from './enhance';

const TotalOrderHistory = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const configColumn = columnTotalOrderHistory(t);
  const [loadingSearch, setLoadingSearch] = useState(false);
  // eslint-disable-next-line prefer-template
  const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';
  const [param, setParam] = useState({
    minibarCode: null,
    startDate: formatTime(firstDayOfMonth, dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    productName: null,
    phone: null,
    totalAmount: null,
    type: 'Total',
    page: 0
  });
  const [minibar, setMinibar] = useState(null);
  const [state, setState] = React.useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [selected, setSelected] = useState(null);
  const { items, pagination } = state;
  const [selectOptionSearch, setSelectOptionSearch] = useState({
    key: 'phone',
    value: ''
  });
  const [receiptInfo, setReceiptInfo] = useState({
    phone: '',
    purchaseCode: ''
  });
  const getTotalOrderHistory = async (param) => {
    setLoadingSearch(true);
    const result = await totalOrderService.getTotalOrders({
      ...param,
      type: param.type === 'Total' ? null : param.type
    });
    if (!result.error) {
      const { listPurchase, ...rest } = result.data.data;
      let id = null;
      let no = 0;
      const arrItems =
        listPurchase?.items?.map((i, idx) => {
          if (id !== i?.id) {
            id = i?.id;
            no += 1;
          }
          return { index: idx, no, ...i };
        }) || [];
      setState({
        ...state,
        items: arrItems,
        pagination: {
          ...state.pagination,
          pageSize: listPurchase?.pageSize || 0,
          page: listPurchase?.page || 0,
          totalRow: listPurchase?.totalRow || 0,
          count: listPurchase?.totalPage || 0
        },
        ...rest
      });
    } else {
      setState({ ...state, items: [] });
    }
    setLoadingSearch(false);
  };
  const createMember = async (data) => {
    if (data?.phone && data?.purchaseCode) {
      const rs = await totalOrderService.createMember(data);
      if (!rs.error) {
        getTotalOrderHistory({ ...param, page: 0 });
        setSelected({ ...selected, open: false });
      } else {
        dispatch(setError(error2Text(rs?.error)));
      }
    }
  };
  const getPaymentHistory = async (id) => {
    const rs = await totalOrderService.getPaymentHistory(id);
    if (!rs.error) {
      let id = null;
      let no = 0;
      const arrItems =
        rs?.data?.data?.map((i, idx) => {
          if (id !== i?.id) {
            id = i?.id;
            no += 1;
          }
          return { index: idx, no, ...i };
        }) || [];
      setSelected({
        open: true,
        title: t('pages.statistics.payment-details'),
        action: 'view-payment',
        items: arrItems
      });
    }
  };
  const exportTotalOrders = async () => {
    const paramSubmit = {};
    const keys = Object.keys(param);
    const values = Object.values(param);
    keys.forEach((item, index) => {
      if (values[index])
        paramSubmit[item] = values[index] === 'Total' ? null : values[index];
    });
    const rs = await totalOrderService.exportTotalOrder(paramSubmit);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  useEffect(() => {
    const query = paramsToObj(location?.search);
    const {
      startDate,
      endDate,
      page,
      minibarCode,
      productName,
      phone,
      totalAmount,
      type
    } = query;
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';
    const dataParams = {
      minibarCode: minibarCode || null,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      productName: productName || null,
      phone: phone || null,
      totalAmount: totalAmount || null,
      type: type || 'Total',
      page: page - 1 || 0
    };
    setMinibar({ ...minibar, minibarCode: minibarCode || null });
    setParam(dataParams);
    setSelectOptionSearch({
      ...selectOptionSearch,
      key:
        (phone && 'phone') ||
        (productName && 'productName') ||
        (totalAmount && 'totalAmount') ||
        'phone',
      value: phone || productName || totalAmount || ''
    });
    getTotalOrderHistory(dataParams);
  }, [location?.search]);

  const onAction = async (action, value) => {
    if (action === 'search') {
      const { startDate, endDate, type, phone, productName, totalAmount } =
        param;
      const dataParams = {
        minibarCode: minibar?.minibarCode,
        startDate,
        endDate,
        type,
        phone,
        productName,
        totalAmount
      };
      const query = objToParams(
        ignoreEmpty({
          ...dataParams,
          page: 1
        })
      );
      getTotalOrderHistory({
        ...dataParams,
        page: 0
      });
      navigate(query);
    }
    if (action === 'searchTyping') {
      setSelectOptionSearch({
        key: value.target.name,
        value: value.target.value
      });
      setParam({
        ...param,
        [value.target.name]: value.target.value || null
      });
    }
    if (action === 'selectMinibar') {
      setParam({
        ...param,
        minibarCode: value?.minibarCode || null
      });
    }
    if (action === 'selectSearchType') {
      setSelectOptionSearch({
        key: value.target.value,
        value: ''
      });
      setParam({
        ...param,
        phone: null,
        productName: null,
        totalAmount: null
      });
    }
    if (action === 'selectPeriod' && value?.key === 'selection') {
      setParam({
        ...param,
        startDate: formatTime(value.startDate, dateFormat),
        endDate: formatTime(value.endDate, dateFormat)
      });
    }
    if (action === 'clickRowTable') {
      if (value?.isMember === 'Y') getPaymentHistory(value?.memberCode);
      else {
        setReceiptInfo({ ...receiptInfo, purchaseCode: value?.purchaseCode });
        setSelected({
          open: true,
          title: t('pages.product.receipt-issuance'),
          action: 'receipt-inssuance'
        });
      }
    }
    if (action === 'changePage') {
      setParam({ ...param, page: value });
      const query = objToParams(
        ignoreEmpty({
          ...param,
          page: value + 1
        })
      );
      navigate(query);
    }
    if (action === 'ReceiptIssuance') {
      setReceiptInfo({
        ...receiptInfo,
        phone: value.target.value
      });
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.totalOrderHistory')}
      content="Total order history page"
    >
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('labels.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1,
                width: 60
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                setState={(e) => onAction('selectPeriod', e)}
              />
            }
          />
        </FormControl>
        <MinibarAutoCompleteV2
          minibar={minibar}
          setMinibar={(minibar) => {
            setMinibar(minibar);
            setParam({ ...param, minibarCode: minibar?.minibarCode });
          }}
          onKeyPress={({ charCode }) => charCode === 13 && onAction('search')}
        />
        <MySelect
          label={t('labels.condition')}
          defaultValue="phone"
          data={searchOptions(t)}
          onChange={(e) => onAction('selectSearchType', e)}
          sx={{ mr: 1.5 }}
        />
        <MyTextField
          name={selectOptionSearch?.key}
          value={selectOptionSearch?.value}
          onChange={(e) => onAction('searchTyping', e)}
          placeholder={t('button.search')}
          onKeyPress={({ charCode }) => charCode === 13 && onAction('search')}
        />
        <Button
          disabled={loadingSearch}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={() => onAction('search')}
        >
          {loadingSearch ? <CircularProgress size={20} /> : t('button.search')}
        </Button>
        <FormControl>
          <FormControlLabel
            label={t('labels.type')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1,
                width: 60
              }
            }}
            control={
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {radioOptions(t).map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={
                      <Radio
                        onClick={(e) =>
                          setParam({ ...param, type: e.target.value })
                        }
                        checked={param.type === item.value}
                        size="small"
                      />
                    }
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            }
          />
        </FormControl>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '20px' }}>
        <MyLoadingButton variant="outlined" onClick={exportTotalOrders}>
          {t('button.saveExcel')}
        </MyLoadingButton>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination.totalRow}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <Stack
        bgcolor="#F6FAFF"
        direction="row"
        alignItems="center"
        justifyContent="center"
        margin="20px 0"
        padding="15px 0"
        borderRadius={1}
      >
        <Typography>
          {t('pages.product.totalOrder')}
          {'  '}
          <Typography component="span" fontWeight="bold">
            {formatMoney(state.totalOrder)}
          </Typography>
          {t('pages.dashboard.won')}{' '}
          <span style={{ fontSize: 17, fontWeight: 'bold', margin: '0 5px' }}>
            –
          </span>{' '}
          {t('pages.product.cancel')}
          {'  '}
          <Typography component="span" fontWeight="bold">
            {formatMoney(state.totalRefund)}
          </Typography>
          {t('pages.dashboard.won')}{' '}
          <span style={{ fontSize: 17, fontWeight: 'bold', margin: '0 5px' }}>
            –
          </span>{' '}
          {t('pages.product.discount')}{' '}
          <Typography component="span" fontWeight="bold">
            {formatMoney(state.totalDiscount)}
          </Typography>
          {t('pages.dashboard.won')}{' '}
          <span style={{ fontSize: 17, fontWeight: 'bold', margin: '0 5px' }}>
            –
          </span>{' '}
          {t('pages.product.discard')}{' '}
          <Typography component="span" fontWeight="bold">
            {formatMoney(state.totalDiscard)}
          </Typography>
          {t('pages.dashboard.won')}{' '}
          <span style={{ fontSize: 17, fontWeight: 'bold', margin: '0 5px' }}>
            –
          </span>{' '}
          {t('pages.product.loss')}{' '}
          <Typography component="span" fontWeight="bold">
            {formatMoney(state.totalLosses)}
          </Typography>
          {t('pages.dashboard.won')}{' '}
          <span style={{ fontSize: 20, fontWeight: 'bold', margin: '0 5px' }}>
            =
          </span>{' '}
          <Typography
            component="span"
            color="primary.darker"
            fontWeight="bold"
            fontSize={22}
          >
            {formatMoney(state.totalAmount)}
          </Typography>
          <Typography component="span" fontSize={22}>
            {t('pages.dashboard.won')}
          </Typography>
        </Typography>
      </Stack>
      <GroupTableCustomize
        hover
        onRowClick={(e) => onAction('clickRowTable', e)}
        columns={configColumn}
        data={items || []}
        pagination={pagination}
        minusHeight={365}
        onChangePage={(e, page) => onAction('changePage', page)}
      />
      <MyDialog
        open={selected?.open}
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        title={selected?.title}
        hasCloseButton
        hasCancelButton={selected?.action !== 'view-payment'}
        onOk={
          selected?.action === 'view-payment'
            ? () => {
                setSelected({ ...selected, open: false });
              }
            : () => {
                createMember(receiptInfo);
              }
        }
      >
        {selected?.action === 'view-payment' ? (
          <PaymentDetails items={selected?.items} />
        ) : (
          <ReceiptIssuance onChange={(e) => onAction('ReceiptIssuance', e)} />
        )}
      </MyDialog>
    </HelmetContainer>
  );
};

export default TotalOrderHistory;
