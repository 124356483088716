import {
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import LogoBlack from 'images/logo_bk.svg';
import jwtDecode from 'jwt-decode';

import { ReactComponent as LockIcon } from '@svg/lock.svg';
import { ReactComponent as UserIcon } from '@svg/user.svg';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { setError } from '@store/reducers/appReducer';
import authServices from '@services/auth';
import { setAuthenticated, setProfile } from '@store/reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import error2Text from '@utils/error2Text';

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    username: '',
    password: '',
    remember: false
  });
  const onLogin = async () => {
    const { username, password, remember } = loginInfo;
    if (username && password) {
      setLoading(true);
      const { error: resError, data: resData } = await authServices.login({
        username,
        password
      });
      if (resError) {
        dispatch(setError(error2Text(resError)));
      } else if (resData) {
        const { name, sub } = jwtDecode(resData?.accessToken);
        dispatch(
          setProfile({
            name,
            id_admin: sub
          })
        );
        localStorage.setItem(
          'profile',
          JSON.stringify({ name, id_admin: sub })
        );
        localStorage.setItem('token', resData?.accessToken);
        localStorage.setItem('refreshToken', resData?.refreshToken);
        if (navigator.userAgent) {
          localStorage.setItem('sessionID', resData?.sessionID);
        }
        // eslint-disable-next-line no-unused-expressions
        remember
          ? localStorage.setItem('username', username)
          : localStorage.removeItem('username');

        dispatch(setAuthenticated(true));
        navigate('/total-order-history');
      }
      setLoading(false);
    } else if (!username) {
      dispatch(setError({ title: '아이디를 입력해주세요' }));
    } else if (!password) {
      dispatch(setError({ title: '비밀번호를 입력해주세요' }));
    } else dispatch(setError({ title: '아이디를 입력해주세요' }));
  };
  const handleChange = (e) => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const isRemember = localStorage.getItem('username');
    if (isRemember) {
      setLoginInfo({
        ...loginInfo,
        username: localStorage.getItem('username'),
        remember: true
      });
    }
  }, []);
  return (
    <Container maxWidth="sm" sx={{ bgcolor: '#D3D3D3' }}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Paper
          component={Stack}
          justifyContent="center"
          alignItems="center"
          sx={{ p: '40px', width: '365px', borderRadius: '20px' }}
        >
          <img
            src={LogoBlack}
            alt="workers high"
            style={{ width: '156px', height: '26px' }}
          />
          <Typography
            sx={{
              color: 'text.secondary',
              textAlign: 'center',
              fontSize: 12,
              my: 2
            }}
          >
            세션 시작을 위해 로그인 해주세요.
          </Typography>
          <Stack
            flexDirection="column"
            alignItems="center"
            width="100%"
            gap={2}
          >
            <TextField
              name="username"
              autoComplete="username"
              autoFocus
              value={loginInfo.username || null}
              onChange={handleChange}
              onKeyPress={(e) => e.key === 'Enter' && onLogin()}
              fullWidth
              label="아이디"
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ mx: 0.5 }} position="end">
                    <IconButton edge="end" disabled>
                      <UserIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              type="password"
              name="password"
              value={loginInfo.password || null}
              onChange={handleChange}
              onKeyPress={(e) => e.key === 'Enter' && onLogin()}
              fullWidth
              label="비밀번호"
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ mx: 0.5 }} position="end">
                    <IconButton edge="end" disabled>
                      <LockIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={loginInfo.remember}
                  onChange={(e) =>
                    setLoginInfo({
                      ...loginInfo,
                      remember: e.target.checked
                    })
                  }
                />
              }
              label="아이디 기억하기"
              sx={{
                justifyContent: 'center',
                '.MuiFormControlLabel-label': {
                  color: 'text.secondary',
                  fontSize: 14
                }
              }}
            />
            <LoadingButton
              onClick={onLogin}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
            >
              로그인
            </LoadingButton>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};
