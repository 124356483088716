export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        elevation: 0
      },

      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          }
        }
      }
    }
  };
}
