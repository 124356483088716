import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, 'pages.contactForm');
export const provinces = [
  { value: 'seoul', sendValue: '서울', label: '서울' },
  { value: 'busan', sendValue: '부산', label: '부산' },
  { value: 'incheon', sendValue: '인천', label: '인천' },
  { value: 'daegu', sendValue: '대구', label: '대구' },
  { value: 'gwangju', sendValue: '광주', label: '광주' },
  { value: 'daejeon', sendValue: '대전', label: '대전' },
  { value: 'ulsan', sendValue: '울산', label: '울산' },
  { value: 'sejong', sendValue: '세종', label: '세종' },
  { value: 'gyeonggi', sendValue: '경기도', label: '경기도' },
  { value: 'gangwon', sendValue: '강원도', label: '강원도' },
  { value: 'chungbuk', sendValue: '충청북도', label: '충청북도' },
  { value: 'chungnam', sendValue: '충청남도', label: '충청남도' },
  { value: 'jeonbuk', sendValue: '전라북도', label: '전라북도' },
  { value: 'jeonnam', sendValue: '전라남도', label: '전라남도' },
  { value: 'gyeongbuk', sendValue: '경상북도', label: '경상북도' },
  { value: 'gyeongnam', sendValue: '경상남도', label: '경상남도' },
  { value: 'jeju', sendValue: '제주도', label: '제주도' }
];

export const districts = {
  seoul: [
    { value: 'jongno', sendValue: '종로구', label: '종로구' },
    { value: 'gangnam', sendValue: '강남구', label: '강남구' },
    { value: 'gangbuk', sendValue: '강북구', label: '강북구' },
    { value: 'dobong', sendValue: '도봉구', label: '도봉구' },
    { value: 'dongdaemun', sendValue: '동대문구', label: '동대문구' },
    { value: 'dongjak', sendValue: '동작구', label: '동작구' },
    { value: 'eunpyeong', sendValue: '은평구', label: '은평구' },
    { value: 'gangseo', sendValue: '강서구', label: '강서구' },
    { value: 'geumcheon', sendValue: '금천구', label: '금천구' },
    { value: 'guro', sendValue: '구로구', label: '구로구' },
    { value: 'gwanak', sendValue: '관악구', label: '관악구' },
    { value: 'gwangjin', sendValue: '광진구', label: '광진구' },
    { value: 'jangangu', sendValue: '중구', label: '중구' },
    { value: 'jungnang', sendValue: '중랑구', label: '중랑구' },
    { value: 'mapo', sendValue: '마포구', label: '마포구' },
    { value: 'nowon', sendValue: '노원구', label: '노원구' },
    { value: 'seocho', sendValue: '서초구', label: '서초구' },
    { value: 'seodaemun', sendValue: '서대문구', label: '서대문구' },
    { value: 'sungbuk', sendValue: '성북구', label: '성북구' },
    { value: 'suseong', sendValue: '수성구', label: '수성구' },
    { value: 'yangcheon', sendValue: '양천구', label: '양천구' },
    { value: 'yeongdeungpo', sendValue: '영등포구', label: '영등포구' },
    { value: 'yongsan', sendValue: '용산구', label: '용산구' }
  ],
  busan: [
    { value: 'haeundae', sendValue: '해운대구', label: '해운대구' },
    { value: 'dongnae', sendValue: '동래구', label: '동래구' },
    { value: 'bukgu', sendValue: '북구', label: '북구' },
    { value: 'gangseo', sendValue: '강서구', label: '강서구' },
    { value: 'geumjeong', sendValue: '금정구', label: '금정구' },
    { value: 'gijang', sendValue: '기장군', label: '기장군' },
    { value: 'namgu', sendValue: '남구', label: '남구' },
    { value: 'donggu', sendValue: '동구', label: '동구' },
    { value: 'saha', sendValue: '사하구', label: '사하구' },
    { value: 'sasang', sendValue: '사상구', label: '사상구' },
    { value: 'seo', sendValue: '서구', label: '서구' },
    { value: 'suyeong', sendValue: '수영구', label: '수영구' },
    { value: 'yeonje', sendValue: '연제구', label: '연제구' },
    { value: 'yongsan', sendValue: '영산구', label: '영산구' }
  ],
  incheon: [
    { value: 'junggu', sendValue: '중구', label: '중구' },
    { value: 'donggu', sendValue: '동구', label: '동구' },
    { value: 'namgu', sendValue: '남구', label: '남구' },
    { value: 'bupyeong', sendValue: '부평구', label: '부평구' },
    { value: 'gyeyang', sendValue: '계양구', label: '계양구' },
    { value: 'yeonsu', sendValue: '연수구', label: '연수구' },
    { value: 'ganghwa', sendValue: '강화군', label: '강화군' },
    { value: 'ongjin', sendValue: '옹진군', label: '옹진군' }
  ],

  daegu: [
    { value: 'junggu', sendValue: '중구', label: '중구' },
    { value: 'donggu', sendValue: '동구', label: '동구' },
    { value: 'namgu', sendValue: '남구', label: '남구' },
    { value: 'seogu', sendValue: '서구', label: '서구' },
    { value: 'suseong', sendValue: '수성구', label: '수성구' },
    { value: 'dalseo', sendValue: '달서구', label: '달서구' },
    { value: 'dalseong', sendValue: '달성군', label: '달성군' }
  ],
  gwangju: [
    { value: 'donggu', sendValue: '동구', label: '동구' },
    { value: 'seogu', sendValue: '서구', label: '서구' },
    { value: 'namgu', sendValue: '남구', label: '남구' },
    { value: 'bukgu', sendValue: '북구', label: '북구' }
  ],

  daejeon: [
    { value: 'donggu', sendValue: '동구', label: '동구' },
    { value: 'seogu', sendValue: '서구', label: '서구' },
    { value: 'yuseong', sendValue: '유성구', label: '유성구' },
    { value: 'daedeok', sendValue: '대덕구', label: '대덕구' }
  ],

  ulsan: [
    { value: 'junggu', sendValue: '중구', label: '중구' },
    { value: 'namgu', sendValue: '남구', label: '남구' },
    { value: 'bukgu', sendValue: '북구', label: '북구' },
    { value: 'ulju', sendValue: '울주군', label: '울주군' }
  ],
  sejong: [
    { value: 'jochiwon', sendValue: '조치원', label: '조치원' },
    { value: 'seogwipo', sendValue: '서귀포', label: '서귀포' }
  ],

  gyeonggi: [
    { value: 'suwon', sendValue: '수원', label: '수원' },
    { value: 'ansan', sendValue: '안산', label: '안산' },
    { value: 'anyang', sendValue: '안양', label: '안양' },
    { value: 'bucheon', sendValue: '부천', label: '부천' },
    { value: 'cheonan', sendValue: '천안', label: '천안' },
    { value: 'dongtan', sendValue: '동탄', label: '동탄' },
    { value: 'goyang', sendValue: '고양', label: '고양' },
    { value: 'gwacheon', sendValue: '과천', label: '과천' },
    { value: 'gyeonggi-do', sendValue: '경기도', label: '경기도' },
    { value: 'gyeongsan', sendValue: '경산', label: '경산' },
    { value: 'hwaseong', sendValue: '화성', label: '화성' },
    { value: 'icheon', sendValue: '이천', label: '이천' },
    { value: 'osan', sendValue: '오산', label: '오산' },
    { value: 'pyeongtaek', sendValue: '평택', label: '평택' },
    { value: 'seongnam', sendValue: '성남', label: '성남' },
    { value: 'siheung', sendValue: '시흥', label: '시흥' },
    { value: 'suwon', sendValue: '수원', label: '수원' },
    { value: 'uijeongbu', sendValue: '의정부', label: '의정부' },
    { value: 'yongin', sendValue: '용인', label: '용인' }
  ],

  gangwon: [
    { value: 'chuncheon', sendValue: '춘천', label: '춘천' },
    { value: 'wonju', sendValue: '원주', label: '원주' },
    { value: 'donghae', sendValue: '동해', label: '동해' },
    { value: 'gangneung', sendValue: '강릉', label: '강릉' },
    { value: 'sokcho', sendValue: '속초', label: '속초' },
    { value: 'taebaek', sendValue: '태백', label: '태백' },
    { value: 'yanggu', sendValue: '양구', label: '양구' },
    { value: 'yeongwol', sendValue: '영월', label: '영월' }
  ],

  chungbuk: [
    { value: 'cheongju', sendValue: '청주', label: '청주' },
    { value: 'chungju', sendValue: '충주', label: '충주' },
    { value: 'boeun', sendValue: '보은', label: '보은' },
    { value: 'jeungpyeong', sendValue: '증평', label: '증평' },
    { value: 'jincheon', sendValue: '진천', label: '진천' },
    { value: 'eumseong', sendValue: '음성', label: '음성' },
    { value: 'goesan', sendValue: '괴산', label: '괴산' },
    { value: 'danyang', sendValue: '단양', label: '단양' },
    { value: 'geumsan', sendValue: '금산', label: '금산' },
    { value: 'gongju', sendValue: '공주', label: '공주' },
    { value: 'jecheon', sendValue: '제천', label: '제천' }
  ],

  chungnam: [
    { value: 'daejeon', sendValue: '대전', label: '대전' },
    { value: 'sejong', sendValue: '세종', label: '세종' },
    { value: 'asansi', sendValue: '아산시', label: '아산시' },
    { value: 'boryeong', sendValue: '보령시', label: '보령시' },
    { value: 'buyeo', sendValue: '부여군', label: '부여군' },
    { value: 'cheonan', sendValue: '천안', label: '천안' },
    { value: 'cheongyang', sendValue: '청양군', label: '청양군' },
    { value: 'geumsan', sendValue: '금산군', label: '금산군' },
    { value: 'gongju', sendValue: '공주', label: '공주' },
    { value: 'gyeryong', sendValue: '계룡시', label: '계룡시' },
    { value: 'hongseong', sendValue: '홍성군', label: '홍성군' },
    { value: 'nonsan', sendValue: '논산시', label: '논산시' },
    { value: 'seosan', sendValue: '서산시', label: '서산시' },
    { value: 'taean', sendValue: '태안군', label: '태안군' },
    { value: 'yeongi', sendValue: '영암군', label: '영암군' }
  ],

  jeonbuk: [
    { value: 'jeonju', sendValue: '전주', label: '전주' },
    { value: 'gunsan', sendValue: '군산', label: '군산' },
    { value: 'gimje', sendValue: '김제시', label: '김제시' },
    { value: 'gochang', sendValue: '고창군', label: '고창군' },
    { value: 'jeongeup', sendValue: '정읍시', label: '정읍시' },
    { value: 'jinan', sendValue: '진안군', label: '진안군' },
    { value: 'muju', sendValue: '무주군', label: '무주군' },
    { value: 'namwon', sendValue: '남원시', label: '남원시' },
    { value: 'sunchang', sendValue: '순창군', label: '순창군' },
    { value: 'wanju', sendValue: '완주군', label: '완주군' }
  ],

  jeonnam: [
    { value: 'gwangyang', sendValue: '광양시', label: '광양시' },
    { value: 'mokpo', sendValue: '목포', label: '목포' },
    { value: 'boseong', sendValue: '보성군', label: '보성군' },
    { value: 'damyang', sendValue: '담양군', label: '담양군' },
    { value: 'goheung', sendValue: '고흥군', label: '고흥군' },
    { value: 'haenam', sendValue: '해남군', label: '해남군' },
    { value: 'hampyeong', sendValue: '함평군', label: '함평군' },
    { value: 'jangheung', sendValue: '장흥군', label: '장흥군' },
    { value: 'jinan', sendValue: '진안군', label: '진안군' },
    { value: 'muan', sendValue: '무안군', label: '무안군' },
    { value: 'naju', sendValue: '나주시', label: '나주시' },
    { value: 'suncheon', sendValue: '순천', label: '순천' },
    { value: 'wando', sendValue: '완도군', label: '완도군' },
    { value: 'yeosu', sendValue: '여수', label: '여수' }
  ],

  gyeongbuk: [
    { value: 'andong', sendValue: '안동', label: '안동' },
    { value: 'gumi', sendValue: '구미', label: '구미' },
    { value: 'goryeong', sendValue: '고령군', label: '고령군' },
    { value: 'gyeongsan', sendValue: '경산', label: '경산' },
    { value: 'gunwi', sendValue: '군위군', label: '군위군' },
    { value: 'gyeongju', sendValue: '경주시', label: '경주시' },
    { value: 'mungyeong', sendValue: '문경시', label: '문경시' },
    { value: 'pohang', sendValue: '포항', label: '포항' },
    { value: 'sangju', sendValue: '상주시', label: '상주시' },
    { value: 'seongju', sendValue: '성주군', label: '성주군' },
    { value: 'yecheon', sendValue: '예천군', label: '예천군' },
    { value: 'yeongcheon', sendValue: '영천', label: '영천' },
    { value: 'yeongdeok', sendValue: '영덕군', label: '영덕군' },
    { value: 'yeongju', sendValue: '영주', label: '영주' },
    { value: 'yeongyang', sendValue: '영양군', label: '영양군' }
  ],

  gyeongnam: [
    { value: 'changwon', sendValue: '창원', label: '창원' },
    { value: 'geoje', sendValue: '거제', label: '거제' },
    { value: 'gimhae', sendValue: '김해', label: '김해' },
    { value: 'haman', sendValue: '함안군', label: '함안군' },
    { value: 'hampyeong', sendValue: '함평군', label: '함평군' },
    { value: 'jangseong', sendValue: '장성군', label: '장성군' },
    { value: 'miryang', sendValue: '밀양', label: '밀양' },
    { value: 'namhae', sendValue: '남해군', label: '남해군' },
    { value: 'sacheon', sendValue: '사천', label: '사천' },
    { value: 'sancheong', sendValue: '산청군', label: '산청군' },
    { value: 'tongyeong', sendValue: '통영', label: '통영' },
    { value: 'yangsan', sendValue: '양산', label: '양산' }
  ],

  jeju: [
    { value: 'jeju', sendValue: '제주', label: '제주' },
    { value: 'seogwipo', sendValue: '서귀포', label: '서귀포' }
  ]
};
