import React, { forwardRef } from 'react';
import { FormControl, FormControlLabel, TextField } from '@mui/material';
import { isNumber } from '@utils/helpers';

const MyTextField = forwardRef(
  ({ label, sx, errMg, required, onKeyDown, ...rest }, ref) => {
    if (!label)
      return (
        <FormControl sx={{ ...sx }}>
          <TextField
            size="small"
            sx={{
              '.MuiInputBase-root': {
                bgcolor: 'common.white'
              }
            }}
            onPaste={(e) => {
              const value = e.clipboardData.getData('text');
              if (onKeyDown) {
                if (!isNumber(value.replace(/,/g, ''))) {
                  e.preventDefault();
                }
              }
            }}
            onKeyDown={onKeyDown}
            error={errMg}
            inputRef={ref}
            {...rest}
          />
          {errMg && (
            <span
              style={{
                color: 'red',
                fontSize: 12
              }}
            >
              * {errMg}
            </span>
          )}
        </FormControl>
      );
    return (
      <FormControl sx={{ flexDirection: 'row' }} error={errMg}>
        <FormControlLabel
          label={
            <span>
              {label} <span style={{ color: 'red' }}>{required && '*'}</span>
            </span>
          }
          labelPlacement="start"
          sx={{
            '.MuiTypography-root': {
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap'
            }
          }}
          control={
            <TextField
              size="small"
              sx={{
                '.MuiInputBase-root': { bgcolor: 'common.white' },
                ml: 1
              }}
              onPaste={(e) => {
                const value = e.clipboardData.getData('text');
                if (onKeyDown) {
                  if (!isNumber(value.replace(/,/g, ''))) {
                    e.preventDefault();
                  }
                }
              }}
              onKeyDown={onKeyDown}
              inputRef={ref}
              {...rest}
            />
          }
        />
        {errMg && (
          <span
            style={{
              color: 'red',
              fontSize: 12
            }}
          >
            * {errMg}
          </span>
        )}
      </FormControl>
    );
  }
);

export default MyTextField;
