import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as EmptyBoxIcon } from '@svg/empty-box.svg';

const NoData = ({ description = 'No data' }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ my: 4, mx: 1 }}
      component="span"
    >
      <EmptyBoxIcon />
      <Typography
        sx={{
          color: 'rgba(0,0,0,.25)',
          fontSize: 14,
          textAlign: 'center',
          mt: 1
        }}
      >
        {description}
      </Typography>
    </Stack>
  );
};

export default NoData;
