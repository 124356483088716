/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sub } from 'date-fns';
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';
import { dateFormat } from '@constants/utils';
import error2Text from '@utils/error2Text';
import { Formatter } from '@utils/formatter';
import {
  compareDates,
  formatMoney,
  ignoreEmpty,
  objToParams,
  paramsToObj
} from '@utils/helpers';
import MyTable from '@components/MyTable';
import MyDialog from '@components/MyDialog';
import SearchBar from '@components/SearchBar';
import DateRangePicker from '@components/DateRangePicker';
import HelmetContainer from '@components/HelmetContainer';
import MyLoadingButton from '@components/MyLoadingButton';
import { MinibarAutoCompleteV2 } from '@components/MyAutocomplete';
import { useLocation, useNavigate } from 'react-router-dom';
import { setError } from '@store/reducers/appReducer';
import statisticsByMemberService from '@services/statisticsByMember';
import PaymentDetail from './components/PaymentDetail';
import { columns } from './enhance';

const ByMember = () => {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [param, setParam] = useState({
    startDate: formatTime(sub(new Date(), { months: 1, days: 1 }), dateFormat),
    endDate: formatTime(sub(new Date(), { days: 1 }), dateFormat),
    minibarCode: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });
  const [minibar, setMinibar] = useState(null);
  const [selected, setSelected] = useState(null);
  const handleDatetime = (e) => {
    if (
      compareDates(
        formatTime(e.startDate, dateFormat),
        formatTime(new Date(), dateFormat)
      ) === -1 &&
      compareDates(
        formatTime(e.endDate, dateFormat),
        formatTime(new Date(), dateFormat)
      ) === -1
    ) {
      setParam({
        ...param,
        startDate: formatTime(e.startDate, dateFormat),
        endDate: formatTime(e.endDate, dateFormat)
      });
    } else {
      dispatch(
        setError({
          title: t('error.INVALID_DATA.title'),
          content: t('validation.dateMustBeInThePast')
        })
      );
    }
  };
  const viewPayment = async (item, action) => {
    const rs = await statisticsByMemberService.getPaymentDetail(
      item?.memberCode,
      action,
      { startDate: param?.startDate, endDate: param?.endDate }
    );
    if (!rs?.error) {
      const { memberName, quantity, totalPrice, phone } = rs.data.member;
      setSelected({
        ...selected,
        open: true,
        title: t('pages.statistics.payment-history'),
        subTitle: `${t('common.member')}: ${memberName || phone || ' - '}/ ${t(
          'common.totalPurchaseQuantity'
        )}: ${formatMoney(quantity || ' - ')}/ ${t(
          'common.totalPaymentAmount'
        )}: ${formatMoney(totalPrice || ' - ')}`,
        item: rs?.data,
        action
      });
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  const getActionButtons = (item) => {
    return (
      <Stack flexDirection="row" gap={2} justifyContent="space-evenly">
        <Button
          onClick={() => viewPayment(item, 'by-product')}
          variant="outlined"
          sx={{ height: 35, fontSize: 13 }}
        >
          {t('pages.statistics.by-product')}
        </Button>
        <Button
          onClick={() => viewPayment(item, 'by-minibar')}
          variant="outlined"
          sx={{ height: 35, fontSize: 13 }}
        >
          {t('pages.statistics.by-minibar')}
        </Button>
      </Stack>
    );
  };

  const getStatisticsByMember = async (param) => {
    const rs = await statisticsByMemberService.getStatisticsByMember(param);
    if (!rs?.error) {
      setState({
        ...state,
        items: rs?.data?.items || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };
  useEffect(() => {
    const query = paramsToObj(location?.search);
    
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';
    setMinibar({ ...minibar, minibarCode: query?.minibarCode });
    const dataParam = {
      startDate:formatTime(
        query?.startDate || firstDayOfMonth,
        dateFormat
      ),
      endDate: formatTime(
        query?.endDate || sub(new Date(), { days: 1 }),
        dateFormat
      ),
      minibarCode: query?.minibarCode || null,
      page: query?.page - 1 || 0
    };
    setParam(dataParam);
    getStatisticsByMember(dataParam);
  }, [location?.search]);

  const handleSearch = () => {
    const query = objToParams(
      ignoreEmpty({
        ...param,
        minibarCode: param?.minibarCode || '',
        page: 1
      })
    );
    getStatisticsByMember({
      ...param,
      minibarCode: param?.minibarCode || '',
      page: 0
    });
    navigate(query);
  };

  const exportStatistics = async () => {
    const rs = await statisticsByMemberService.exportStatisticsByMember(param);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.statisticsByMember')}
      content="statisticsByMember page"
    >
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('pages.product.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                width: 70,
                textAlign: 'center'
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                setState={handleDatetime}
              />
            }
          />
        </FormControl>
        <MinibarAutoCompleteV2
          minibar={minibar}
          setMinibar={(e) => {
            setMinibar(e);
            setParam({ ...param, minibarCode: e?.minibarCode });
          }}
          onKeyPress={({ charCode }) => charCode === 13 && handleSearch()}
        />
        <Button
          onClick={handleSearch}
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        mb={2}
        mt={2}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <MyLoadingButton variant="outlined" onClick={exportStatistics}>
          {t('button.saveExcel')}
        </MyLoadingButton>
        <Typography
          sx={{
            fontWeight: 'fontWeightSemiMedium',
            fontSize: 15,
            color: 'text.primary'
          }}
          component="span"
        >
          {t('common.number-of-results')}{' '}
          <Typography
            component="span"
            sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
          >
            {state?.pagination?.totalRow}
          </Typography>
        </Typography>
      </Stack>
      <MyTable
        columns={columns}
        rerender
        data={state?.items}
        getActionButtons={getActionButtons}
        pagination={state?.pagination}
        minusHeight={235}
        onChangePage={(e, page) => {
          setParam({ ...param, page });
          const query = objToParams(
            ignoreEmpty({
              ...param,
              page: page + 1
            })
          );
          navigate(query);
        }}
      />
      <MyDialog
        open={selected?.open}
        title={selected?.title}
        setOpen={
          selected?.open
            ? () => setSelected({ ...selected, open: false })
            : () => {}
        }
        subTitle={selected?.subTitle}
      >
        <PaymentDetail data={selected?.item?.payments} />
      </MyDialog>
    </HelmetContainer>
  );
};

export default ByMember;
