import {
  DrawerIcon6,
  DrawerIcon7,
  // BuildingIcon,
  DonutChartIcon
  // DrawerIcon3,
  // DrawerIcon8,
  // DrawerIcon9,
  // LineChartIcon,
  // OrderIcon,
  // PanelIcon,
  // SettingIcon
} from '@svg/drawer';

import i18n from './i18n';

const sidebarConfig = [
  {
    title: i18n.t('menusidebar.account'),
    path: '/account',
    Icon: DrawerIcon6
  },
  {
    title: i18n.t('menusidebar.product-management.total-order-history'),
    path: '/total-order-history',
    Icon: DrawerIcon7
  },
  {
    title: i18n.t('menusidebar.statistics.by-member'),
    path: '/statistics/by-member',
    Icon: DonutChartIcon
  }
];
// const sidebarConfig = [
//   {
//     title: i18n.t('menusidebar.dashboard.label'),
//     path: '/dashboard',
//     Icon: PanelIcon
//   },
//   {
//     title: i18n.t('menusidebar.client-management.label'),
//     path: '/client-management',
//     Icon: BuildingIcon,
//     children: [
//       {
//         title: i18n.t('menusidebar.client-management.client'),
//         path: '/client-management/client'
//       },
//       {
//         title: i18n.t('menusidebar.client-management.minibar'),
//         path: '/client-management/minibar'
//       }
//     ]
//   },
//   {
//     title: i18n.t('menusidebar.equipment-management.label'),
//     path: '/equipment-management',
//     Icon: DrawerIcon3,
//     children: [
//       {
//         title: i18n.t('menusidebar.equipment-management.equipment'),
//         path: '/equipment-management/equipment'
//       },
//       {
//         title: i18n.t('menusidebar.equipment-management.by-minibar'),
//         path: '/equipment-management/by-minibar'
//       }
//     ]
//   },
//   {
//     title: i18n.t('menusidebar.performance-management.label'),
//     path: '/performance-management',
//     Icon: LineChartIcon,
//     children: [
//       {
//         title: i18n.t('menusidebar.performance-management.goal'),
//         path: '/performance-management/goal'
//       },
//       {
//         title: i18n.t('menusidebar.performance-management.sales'),
//         path: '/performance-management/sales'
//       },
//       {
//         title: i18n.t('menusidebar.performance-management.profit-and-loss'),
//         path: '/performance-management/profit-and-loss'
//       }
//       // {
//       //   title: i18n.t('menusidebar.performance-management.register'),
//       //   path: '/performance-management/register'
//       // }
//     ]
//   },
//   {
//     title: i18n.t('menusidebar.statistics.label'),
//     path: '/statistics',
//     Icon: DonutChartIcon,
//     children: [
//       {
//         title: i18n.t('menusidebar.statistics.sales'),
//         path: '/statistics/sales'
//       },
//
//       {
//         title: i18n.t('menusidebar.statistics.by-product'),
//         path: '/statistics/by-product'
//       },
//       {
//         title: i18n.t('menusidebar.statistics.member'),
//         path: '/statistics/member'
//       }
//     ]
//   },
//   {
//     title: i18n.t('menusidebar.member-management.label'),
//     path: '/member-management',
//     Icon: DrawerIcon6,
//     children: [
//       {
//         title: i18n.t('menusidebar.member-management.member'),
//         path: '/member-management/member'
//       },
//       {
//         title: i18n.t('menusidebar.member-management.welfare-points'),
//         path: '/member-management/welfare-points'
//       },
//       {
//         title: i18n.t('menusidebar.member-management.order-history'),
//         path: '/member-management/order-history'
//       }
//     ]
//   },
//   {
//     title: i18n.t('menusidebar.product-management.label'),
//     path: '/product-management',
//     Icon: DrawerIcon7,
//     children: [
//       {
//         title: i18n.t('menusidebar.product-management.total-order-history'),
//         path: '/product-management/total-order-history'
//       },
//       {
//         title: i18n.t('menusidebar.product-management.category'),
//         path: '/product-management/category'
//       },
//       {
//         title: i18n.t('menusidebar.product-management.product'),
//         path: '/product-management/product'
//       },
//       {
//         title: i18n.t('menusidebar.product-management.price-policy-type'),
//         path: '/product-management/price-policy-type'
//       },
//       {
//         title: i18n.t('menusidebar.product-management.curation'),
//         path: '/product-management/curation'
//       },
//       {
//         title: i18n.t('menusidebar.product-management.minibar-order'),
//         path: '/product-management/minibar-order'
//       },
//       {
//         title: i18n.t('menusidebar.product-management.client-order'),
//         path: '/product-management/client-order'
//       }
//     ]
//   },
//   {
//     title: i18n.t('menusidebar.inventory-management.label'),
//     path: '/inventory-management',
//     Icon: DrawerIcon8,
//     children: [
//       {
//         title: i18n.t('menusidebar.inventory-management.vendor'),
//         path: '/inventory-management/vendor'
//       },
//       {
//         title: i18n.t('menusidebar.inventory-management.location'),
//         path: '/inventory-management/location'
//       },
//       {
//         title: i18n.t('menusidebar.inventory-management.warehouse'),
//         path: '/inventory-management/warehouse'
//       },
//       {
//         title: i18n.t('menusidebar.inventory-management.picking'),
//         path: '/inventory-management/picking'
//       },
//       {
//         title: i18n.t('menusidebar.inventory-management.release'),
//         path: '/inventory-management/release'
//       },
//       {
//         title: i18n.t('menusidebar.inventory-management.display'),
//         path: '/inventory-management/display'
//       },
//       {
//         title: i18n.t('menusidebar.inventory-management.restock'),
//         path: '/inventory-management/restock'
//       }
//     ]
//   },
//   {
//     title: i18n.t('menusidebar.display-management.label'),
//     path: '/display-management',
//     Icon: DrawerIcon9,
//     children: [
//       {
//         title: i18n.t('menusidebar.display-management.PIC'),
//         path: '/display-management/PIC'
//       },
//       {
//         title: i18n.t('menusidebar.display-management.schedule'),
//         path: '/display-management/schedule'
//       }
//     ]
//   },
//   {
//     title: i18n.t('menusidebar.order-management.label'),
//     path: '/order-management',
//     Icon: OrderIcon,
//     children: [
//       {
//         title: i18n.t('menusidebar.order-management.vendor'),
//         path: '/order-management/vendor'
//       }
//     ]
//   },
//   {
//     title: i18n.t('menusidebar.manager.label'),
//     path: '/manager',
//     Icon: SettingIcon,
//     children: [
//       {
//         title: i18n.t('menusidebar.manager.development-request'),
//         path: '/manager/development-request'
//       },
//       {
//         title: i18n.t('menusidebar.manager.authority'),
//         path: '/manager/authority'
//       },
//       {
//         title: i18n.t('menusidebar.manager.kiosk'),
//         path: '/manager/kiosk'
//       },
//       {
//         title: i18n.t('menusidebar.manager.APK'),
//         path: '/manager/APK'
//       },
//       { title: i18n.t('menusidebar.manager.AD'), path: '/manager/AD' },
//       {
//         title: i18n.t('menusidebar.manager.usage-history'),
//         path: '/manager/usage-history'
//       },
//       {
//         title: i18n.t('menusidebar.manager.log'),
//         path: '/manager/log'
//       },
//       {
//         title: i18n.t('menusidebar.manager.system-log'),
//         path: '/manager/system-log'
//       }
//     ]
//   }
// ];

export default sidebarConfig;
