import RestService from '@services/RestService';

const consultationInquiryService = {
  async createInquiry(data = {}) {
    try {
      const res = await new RestService()
        .setPath('/consultation-inquiry')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createServiceIntroInquiry(data = {}) {
    try {
      const res = await new RestService()
        .setPath('/introduction-request')
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default consultationInquiryService;
