import MyTextField from '@components/MyTextField';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ReceiptIssuance = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column">
      <Stack
        justifyContent="center"
        sx={{
          flexGrow: 1,
          pb: 1
        }}
      >
        <Typography
          sx={{
            width: 110,
            fontSize: 13,
            color: '#6F869C'
          }}
        >
          {t('common.phoneNo')}
        </Typography>
      </Stack>
      <MyTextField
        placeholder={t('common.phoneNo')}
        name="phone"
        onChange={onChange}
      />
    </Stack>
  );
};

export default ReceiptIssuance;
