import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { ToastContainer } from 'react-toastify';

import { DialogContentText } from '@mui/material';
import MyDialogLP from '@components/dialogs/MyDialog';
import MyDialog from '@components/MyDialog';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import 'react-toastify/dist/ReactToastify.css';
// import 'simplebar-react/dist/simplebar.min.css';

// import 'yet-another-react-lightbox/styles.css';
// import 'yet-another-react-lightbox/plugins/captions.css';
// import 'yet-another-react-lightbox/plugins/counter.css';
// import 'yet-another-react-lightbox/plugins/thumbnails.css';

import './App.css';
import './index.css';
import MySnackbar from '@components/MySnackbar';
import { setInquiry, setSuccess } from '@store/reducers/appReducer';

const Globals = () => {
  const { loading, error, errorLP, info, onOk, success, openInquiry } =
    useSelector((state) => state.app);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (error || info || success || openInquiry) setOpenDialog(true);
  }, [error, info, success, openInquiry]);

  return (
    <>
      {/* <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      /> */}
      {info && (
        <MyDialog
          open={openDialog}
          setOpen={() => {
            setOpenDialog(false);
            onOk();
          }}
          isAlert
          {...info}
        >
          {info?.content && (
            <DialogContentText
              sx={{
                fontSize: 13,
                color: 'primary.main',
                textAlign: 'center',
                lineHeight: '22px',
                whiteSpace: 'pre-line'
              }}
            >
              {info?.content}
            </DialogContentText>
          )}
        </MyDialog>
      )}
      {errorLP && (
        <MyDialogLP
          open={openDialog}
          setOpen={setOpenDialog}
          isAlert
          {...errorLP}
        >
          {errorLP?.content && (
            <DialogContentText
              sx={{
                fontSize: 13,
                color: 'primary.main',
                textAlign: 'center',
                lineHeight: '22px',
                whiteSpace: 'pre-line'
              }}
            >
              {errorLP?.content}
            </DialogContentText>
          )}
        </MyDialogLP>
      )}
      {error && (
        <MyDialog open={openDialog} setOpen={setOpenDialog} isAlert {...error}>
          {error?.content && (
            <DialogContentText
              sx={{
                fontSize: 13,
                color: 'primary.main',
                textAlign: 'center',
                lineHeight: '22px',
                whiteSpace: 'pre-line'
              }}
            >
              {error?.content}
            </DialogContentText>
          )}
        </MyDialog>
      )}
      {success && (
        <MySnackbar
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            dispatch(setSuccess(null));
          }}
          message={success?.message}
        />
      )}
      {openInquiry && (
        <MySnackbar
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            dispatch(setInquiry(false));
          }}
          message={success?.message}
        />
      )}
    </>
  );
};

export default Globals;
