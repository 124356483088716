/* eslint-disable no-nested-ternary */
import { Button, Divider, Hidden, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import i18n from '@utils/i18n';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as LogoWhite } from 'assets/svg/logo_white.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '@components/modals';
import btnMini from 'images/icon/icon-menu-bl.svg';
import btnMinWhite from 'images/icon/icon-menu-wh.svg';
import closeMini from 'images/icon/icon-close.svg';
import { ReactComponent as LanguageIcon } from 'images/icon/language-line.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useChannelIOApi, useChannelIOEvent } from 'react-channel-plugin';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { setLang, setOpenForm } from '@store/reducers/appReducer';
import { formTypes } from '@constants/utils';

import { makeStyles } from '@mui/styles';
import useResponsive from '@hooks/useResponsive';

const aboutUs = i18n.getFixedT(null, null, 'pages.contactForm');
const lang = localStorage.getItem('i18nextLng')?.toLowerCase() || 'ko';
const titles = [aboutUs('title'), aboutUs('consultingReq'), ''];

const useStyles = makeStyles((theme) => ({
  root: {},
  on: {},
  tab_list: {
    transitionDuration: '500ms'
  },
  menu_items_light: {
    // padding: '8px 8px',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '& $menu_items': {
      color: '#FFFFFF !important',
      letterSpacing: '-0.42px'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      padding: '10px', // Adds padding inside the hover area
      margin: '-10px'
    }
  },
  menu_items_dark: {
    // padding: '8px 8px',
    alignItems: 'center',
    justifyContent: 'center',

    '& $menu_items': {
      color: '#6B6C75 !important',
      letterSpacing: '-0.42px'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  },

  lang_menu_light: {
    padding: '10px 12px 10px 16px',
    height: '40px',
    minWidth: '73px',

    alignItems: 'center',
    border: '1px solid white',
    justifyContent: 'center',
    position: 'relative',

    '& $menu_items': {
      color: '#FFFFFF !important'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  },
  lang_menu_dark: {
    padding: '10px 12px 10px 16px',
    height: '40px',
    minWidth: '73px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #364DD1',

    '& $menu_items': {
      color: '#364DD1 !important',
      fontWeight: 500,
      fontSize: 16
    },
    '&:hover': {
      backgroundColor: 'rgba(36, 78, 226, 0.05)'
    }
  },

  selectTransparent: {
    backgroundColor: 'transparent',
    border: '1px solid #FFFFFF',
    color: '#FFFFFF',
    fontSize: 16
  },
  menu_items: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    width: 'auto',
    height: '54px',
    [theme.breakpoints.down('md')]: {
      height: '28px'
    }
  }
}));
const styles = `
  @keyframes fade-down-open {
    0% {
      opacity: 0;
      max-height: 0;
    }
    100% {
      opacity: 1;
      max-height: 1000px;
    }
  }

  @keyframes fade-down-close {
    0% {
      opacity: 1;
      max-height: 1000px; /* Adjust as needed */
    }
    100% {
      opacity: 0;
      max-height: 0;
    }
  }
`;
const NavbarFixed = () => {
  const navigate = useNavigate();
  const isXXL = useResponsive('up', 1920);
  const { openInquiry } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const classes = useStyles();

  const langOptions = [
    {
      value: 'ko',
      label: 'KR'
    },
    { value: 'en', label: 'EN' }
  ];
  const navList = [
    {
      title: t('pages.homepage.navbar.company-introduction'),
      href: '/company-introduction/mission',
      routeKeyword: 'company-introduction',
      children: [
        {
          title: t('pages.homepage.navbar.mission'),
          href: '/company-introduction/mission'
        },
        {
          title: t('pages.homepage.navbar.media'),
          href: '/company-introduction/media'
        }
      ]
    },
    {
      title: t('pages.homepage.navbar.service'),
      href: '/service/offresh',
      routeKeyword: 'service',
      children: [
        {
          title: t('pages.homepage.navbar.offresh'),
          href: '/service/offresh'
        },
        {
          title: t('pages.homepage.navbar.workers-market'),
          href: '/service/WorkersMarket'
        },
        {
          title: t('pages.homepage.navbar.workers-media'),
          href: '/service/media'
        }
      ]
    },
    {
      title: t('pages.homepage.navbar.portfolio'),
      href: '/portfolio',
      routeKeyword: 'portfolio'
    },
    {
      title: t('pages.homepage.navbar.customer-support'),
      href: '/customer-support/notices',
      routeKeyword: 'customer-support',
      children: [
        {
          title: t('pages.homepage.navbar.announcements'),
          href: '/customer-support/notices'
        },
        {
          title: t('pages.homepage.navbar.faq'),
          href: '/customer-support/faq'
        }
      ]
    },
    {
      title: t('pages.homepage.navbar.careers'),
      href: '/careers',
      routeKeyword: 'careers'
    }
  ];

  const { showMessenger } = useChannelIOApi();
  const lang = localStorage.getItem('i18nextLng') || 'ko';
  useChannelIOEvent('onShowMessenger', () => {
    setOpen(false);
  });
  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false);
  const [openMo, setOpenMo] = useState(false);
  const [popupSelected, setPopupSelected] = useState(0);
  const handleSelectPopup = (idx) => {
    if (idx === 4) {
      setOpenMo(!openMo);
    }
  };

  useEffect(() => {
    if (openInquiry) {
      setPopupSelected(1);
      setOpen(true);
    }
  }, [openInquiry]);
  useEffect(() => {
    const handleScroll = () => {
      const winST = window.pageYOffset || document.documentElement.scrollTop;

      if (winST > 0) {
        // Change 64 to the point where you want to trigger sticky state
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // Empty dependency array ensures effect runs only once
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsSticky(false);
  //   }, 0);
  // }, []);
  return (
    <Stack
      id="navBarHeader"
      sx={{
        position: 'fixed',
        display: 'flex',
        backgroundColor: isSticky ? '#FFFFFF' : 'transparent',
        zIndex: 70,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: {
          md: `1px solid ${isSticky ? 'transparent' : '#444649'}`
        },
        height: { md: '64px', xs: '54px', xl: '80px' },
        // Solid background when sticky

        transition:
          'background-color 200ms ease-in-out, border-bottom 500ms ease-in-out' // Smooth transition for background color change
      }}
    >
      <Stack
        // id="menu"
        // className="tab_list"

        sx={{
          width: { md: '75%', lg: '100%', xs: '100%' },
          height: '100%',
          px: { lg: '320px', xs: '20px', md: 0 },
          maxWidth: '1920px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Stack
            sx={{ cursor: 'pointer', mr: { md: 10 }, ml: { md: 0 } }}
            onClick={() => {
              navigate('/');
            }}
          >
            <Hidden mdDown>{isSticky ? <Logo /> : <LogoWhite />}</Hidden>
            <Hidden mdUp>{isSticky ? <Logo /> : <LogoWhite />}</Hidden>
          </Stack>
          <Hidden mdDown>
            {' '}
            <Stack
              className="menu-"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              {navList.map(({ title, href, children }, index) => (
                <Stack
                  key={title}
                  className={
                    isSticky
                      ? classes.menu_items_dark
                      : classes.menu_items_light
                  }
                  sx={{ position: 'relative', cursor: 'pointer' }}
                  onMouseEnter={() => setActiveLink(index)}
                  onMouseLeave={() => setActiveLink(null)}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    className={classes.menu_items}
                    onClick={() => {
                      setActiveLink(index);
                      navigate(href);
                    }}
                  >
                    {title}
                    {children ? (
                      activeLink === index ? (
                        <KeyboardArrowUpIcon sx={{ mt: 0.5 }} />
                      ) : (
                        <KeyboardArrowDownIcon sx={{ mt: 0.5 }} />
                      )
                    ) : null}
                  </Typography>

                  {children && activeLink === index && (
                    <>
                      <div
                        style={{
                          position: 'absolute',
                          top: '100%',
                          left: 0,
                          width: '100%',
                          height: '20%',
                          pointerEvents: 'auto',
                          zIndex: 999
                        }}
                      />
                      <Stack
                        sx={{
                          position: 'absolute',
                          minWidth: 115,
                          maxWidth: 250,
                          width: 'auto',
                          top: '120%', // Starting position just below the parent item
                          left: 0,
                          backgroundColor: 'white',
                          border: '1px solid #F2F2F2',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                          borderRadius: '0px',
                          zIndex: 1000,
                          opacity: activeLink === index ? 1 : 0, // Control visibility
                          transform:
                            activeLink === index
                              ? 'translateY(0)'
                              : 'translateY(-50px)', // Control position
                          transition:
                            'opacity, height 500ms ease-in-out, transform 500ms ease-in-out', // Transition properties
                          pointerEvents: activeLink === index ? 'auto' : 'none' // Ens
                        }}
                      >
                        {children.map(
                          ({ title: childTitle, href: childHref }) => (
                            <Typography
                              key={childTitle}
                              fontSize={14}
                              fontWeight={400}
                              sx={{
                                whiteSpace: 'nowrap',
                                p: 1,
                                '&:hover': {
                                  backgroundColor: '#f0f0f0'
                                }
                              }}
                              onClick={() => navigate(childHref)}
                            >
                              {childTitle}
                            </Typography>
                          )
                        )}
                      </Stack>
                    </>
                  )}
                </Stack>
              ))}
            </Stack>
          </Hidden>
        </Stack>

        <Stack
          className="button-script"
          sx={{ flexDirection: 'row' }}
          alignItems="center"
          gap={1.5}
        >
          <Hidden mdDown>
            <Stack
              className={
                isSticky ? classes.lang_menu_dark : classes.lang_menu_light
              }
              sx={{ position: 'relative', cursor: 'pointer' }}
              onMouseEnter={() => setActiveLink('lang')}
              onMouseLeave={() => setActiveLink(null)}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                className={classes.menu_items}
                onClick={() => {
                  setActiveLink('lang');
                }}
              >
                {langOptions?.find((i) => i?.value === lang)?.label}
                {langOptions ? (
                  activeLink === 'lang' ? (
                    <KeyboardArrowUpIcon sx={{ mt: 0.5 }} />
                  ) : (
                    <KeyboardArrowDownIcon sx={{ mt: 0.5 }} />
                  )
                ) : null}
              </Typography>

              {langOptions && activeLink === 'lang' && (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      width: '100%',
                      height: '30%',
                      pointerEvents: 'auto',
                      zIndex: 999
                    }}
                  />
                  <Stack
                    sx={{
                      position: 'absolute',
                      minWidth: 73,
                      maxWidth: 250,
                      width: 'auto',
                      top: '130%', // Starting position just below the parent item
                      left: 0,
                      backgroundColor: 'white',
                      border: '1px solid #F2F2F2',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      borderRadius: '0px',
                      zIndex: 1000,
                      opacity: activeLink === 'lang' ? 1 : 0, // Control visibility
                      transform:
                        activeLink === 'lang'
                          ? 'translateY(0)'
                          : 'translateY(-50px)', // Control position
                      transition:
                        'opacity, height 500ms ease-in-out, transform 500ms ease-in-out', // Transition properties
                      pointerEvents: activeLink === 'lang' ? 'auto' : 'none' // Ens
                    }}
                  >
                    {langOptions.map(({ value, label }) => (
                      <Typography
                        key={label}
                        fontSize={14}
                        fontWeight={500}
                        sx={{
                          whiteSpace: 'nowrap',
                          p: 1,
                          '&:hover': {
                            backgroundColor: '#f0f0f0'
                          }
                        }}
                        onClick={() => {
                          localStorage.setItem('i18nextLng', value);
                          window.location.reload();
                          dispatch(setLang(value));
                        }}
                      >
                        {label}
                      </Typography>
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
            <Button
              id="script-1"
              variant={isSticky ? 'contained' : 'outlined'}
              sx={{
                boxShadow: 0,
                borderRadius: '0px !important',
                width: { md: 'auto', xs: 'auto' },
                height: { md: 40, xs: 25 },
                fontSize: { md: 16, xs: 11 },
                fontFamily: 'Pretendard',
                fontWeight: 500,
                color: '#FFFFFF',
                backgroundColor: isSticky ? 'primary.main' : ' transparent',
                border: isSticky
                  ? '1px solid transparent !important'
                  : '1px solid #FFFFFF !important',
                ':hover': {
                  backgroundColor: isSticky
                    ? '#1D3FB7'
                    : 'rgba(255, 255, 255, 0.1)',
                  color: '#FFFFFF'
                }
              }}
              onClick={() => {
                dispatch(setOpenForm({ type: formTypes.CONSULTATION }));
                // eslint-disable-next-line no-undef
                fbq('track', 'Contact');
              }}
            >
              {t('button.consultation')}
            </Button>
          </Hidden>

          <Hidden mdUp>
            <Stack onClick={() => handleSelectPopup(4)}>
              <img src={isSticky ? btnMini : btnMinWhite} alt="btn" />
            </Stack>
          </Hidden>
        </Stack>
      </Stack>

      <Modal
        open={openMo}
        setOpen={setOpenMo}
        title={null}
        sx={{
          dialogContent: {
            p: 0
          }
        }}
      >
        <MenuMobile
          setOpen={setOpen}
          setOpenMo={setOpenMo}
          langOptions={langOptions}
          navList={navList}
          activeLink={activeLink}
          setActiveLink={setActiveLink}
          dispatch={dispatch}
        />
      </Modal>
    </Stack>
  );
};

const MenuMobile = ({
  setOpen,
  setOpenMo,
  navList = [],
  activeLink,
  langOptions,
  setActiveLink,
  dispatch
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    setActiveLink((prev) => {
      const currentRoute = navList.find((i) =>
        pathname?.includes(i.routeKeyword)
      );
      return navList.indexOf(currentRoute);
    });
  }, []);
  return (
    <Stack height="100%" bgcolor="white">
      <style>{styles}</style>
      {/* header */}
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: 'white',
          width: '100%',
          px: 2.5,
          height: '63px',
          borderBottom: '1px solid #DEE2E6',
          zIndex: 1000
        }}
      >
        <Stack sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          <Logo />
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Stack onClick={() => setOpenMo(false)}>
            <img src={closeMini} alt="btn" />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        flexDirection="column"
        alignItems="center"
        gap={2}
        mt="40px"
        height="100%"
        px={2.5}
        pb={5}
      >
        <Stack sx={{ width: '100%', flexGrow: 1 }}>
          <Stack
            className="menu-"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={3}
          >
            {navList.map(({ title, href, routeKeyword, children }, index) => (
              <Stack key={title} sx={{ width: '100%', gap: 1.5 }}>
                <Stack
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                  onClick={() => {
                    setActiveLink(activeLink === index ? null : index);
                    if (!children) {
                      navigate(href);
                      setOpenMo(false);
                    }
                  }}
                >
                  <Typography
                    fontSize={24}
                    fontWeight={700}
                    className={classes.menu_items}
                    sx={{
                      color: pathname.includes(routeKeyword)
                        ? '#244EE2'
                        : '#212121'
                    }}
                  >
                    {title}
                  </Typography>
                  {children ? (
                    activeLink === index ? (
                      <KeyboardArrowUpIcon
                        sx={{
                          mt: 0.5,
                          color: '#212121',
                          width: '35px',
                          height: '35px'
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        sx={{
                          mt: 0.5,
                          color: '#212121',
                          width: '35px',
                          height: '35px'
                        }}
                      />
                    )
                  ) : null}
                </Stack>

                {children && activeLink === index && (
                  <Stack
                    sx={{
                      width: 'auto',
                      borderRadius: '0px',
                      pl: 1,
                      gap: 1.5,

                      animation: `${
                        activeLink === index
                          ? 'fade-down-open'
                          : 'fade-down-close'
                      } 500ms ease forwards`,
                      overflow: 'hidden' // To hide the overflow content
                    }}
                  >
                    {children.map(({ title: childTitle, href: childHref }) => (
                      <Typography
                        key={childTitle}
                        fontSize={18}
                        fontWeight={600}
                        sx={{
                          whiteSpace: 'nowrap',
                          color: pathname.includes(childHref)
                            ? '#244EE2'
                            : '#919193',
                          '&:hover': {
                            backgroundColor: '#f0f0f0'
                          }
                        }}
                        onClick={() => {
                          navigate(childHref);
                          setOpenMo(false);
                        }}
                      >
                        {childTitle}
                      </Typography>
                    ))}
                  </Stack>
                )}
              </Stack>
            ))}
          </Stack>
        </Stack>
        {/* Language row */}
        <Stack
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',

              alignItems: 'center',
              gap: 1
            }}
          >
            <LanguageIcon />
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1
              }}
            >
              {langOptions &&
                langOptions.map(({ value, label }, index) => (
                  <Stack
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                    key={`${value} + ${label} +`}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={700}
                      sx={{
                        whiteSpace: 'nowrap',
                        color: '#212121',
                        opacity: lang !== value && 0.3
                      }}
                      onClick={() => {
                        localStorage.setItem('i18nextLng', value);
                        window.location.reload();

                        dispatch(setLang(value));
                      }}
                    >
                      {label}
                    </Typography>
                    {index !== langOptions.length - 1 && (
                      <Stack sx={{ height: '13px' }}>
                        <Divider
                          flexItem
                          sx={{
                            borderColor: '#212121',
                            opacity: 0.3,
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '13px'
                          }}
                          orientation="vertical"
                        />
                      </Stack>
                    )}
                  </Stack>
                ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NavbarFixed;
