/* eslint-disable no-restricted-syntax */
import {
  Box,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/system';

import NoData from '@components/NoData';

import { tableTypes } from '@constants/utils';
import React, { useEffect, useState } from 'react';

const BorderBox = styled(Box)({
  border: '1px solid #D3D3D3',
  borderRadius: '12px'
});

const LinkCell = ({ fileLink, fileName, children, sx, ...rest }) => {
  return (
    <TableCell
      sx={{
        color: 'primary.darker',
        fontWeight: 'fontWeightBold',
        fontSize: 13,
        textDecoration: 'underline',
        ...sx
      }}
      {...rest}
    >
      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
        variant="text"
      >
        {children}
      </Box>
    </TableCell>
  );
};

const TableCellsContainer = ({
  index,
  columns,
  item,
  getActionButtons,
  getModalButton,
  pagination
}) => {
  return columns.map(({ name, type, actionType, formatter, icon }) => {
    const format = formatter ? formatter(item[name]) : item[name];
    const nameFormat =
      item?.[name] !== undefined && item?.[name] !== null ? format : '';
    switch (type) {
      case tableTypes.FILE:
        return (
          <LinkCell
            fileLink={item?.linkFile}
            key={`${item?.id}${name}`}
            fileName={item?.fileName}
          >
            {item?.fileName}
          </LinkCell>
        );
      case tableTypes.ACTION:
        return (
          <TableCell key={`${item?.id}action${actionType}`} sx={{ px: 1 }}>
            {getActionButtons(item, actionType, index)}
          </TableCell>
        );
      case tableTypes.MODAL:
        return (
          <TableCell key={`${item?.id}${name}`} sx={{ px: 1 }}>
            {getModalButton(item)}
          </TableCell>
        );
      case tableTypes.CHECK_MARK:
        return (
          <TableCell key={`${item?.id}${name}`} sx={{ px: 1 }}>
            {item?.[name]?.toUpperCase() === 'Y' ? icon : ''}
          </TableCell>
        );
      default:
        return (
          <TableCell
            key={`${item?.id}${name}`}
            sx={{ color: 'text.primary', fontSize: 13 }}
          >
            {name === '#'
              ? (pagination?.page || 0) * (pagination?.pageSize || 20) +
                index +
                1
              : nameFormat}
          </TableCell>
        );
    }
  });
};

export default function MyTable({
  hover,
  onRowClick,
  getActionButtons,
  getModalButton,
  columns,
  data,
  rerender = false,
  pagination,
  emptyDescription,
  sx,
  paginationAdornment,
  maxHeight,
  minusHeight = 350,
  onChangePage
}) {
  const handleRowClick = (item) => {
    if (onRowClick && !window.getSelection().toString()) onRowClick(item);
  };

  const renderComponent = (render, item, name, index) =>
    render ? render(item, index) : item[name];
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  const scrollToRow = () => {
    const tableContainer = document.getElementById('table-container');
    if (tableContainer) {
      tableContainer.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <TableContainer
        id="table-container"
        component={BorderBox}
        sx={{ mt: '20px', mb: '20px', ...sx }}
        style={{
          maxHeight: maxHeight || `calc(100vh - ${minusHeight + 200}px)`
        }}
      >
        <Table stickyHeader>
          <TableHead
            sx={{
              '.MuiTableCell-root': {
                p: 0,
                height: '50px',
                fontWeight: 'fontWeightBold',
                fontSize: '13px',
                color: '#6F869C',
                lineHeight: '18px',
                borderBottom: '1px solid #D3D3D3',
                '&:not(:last-child)': {
                  borderRight: '1px solid #EEEEEE'
                }
              },
              bgcolor: '#FCFCFC',
              position: 'relative',
              zIndex: 1
            }}
          >
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col.label}
                  align={col.align}
                  sx={{ minWidth: col.minWidth, whiteSpace: 'pre-line' }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data && data?.length > 0 ? (
            <TableBody
              sx={{
                '.MuiTableCell-root': {
                  textAlign: 'center',
                  py: 0,
                  px: 1.5,
                  height: '54px',
                  lineHeight: '22px'
                },
                position: 'relative',
                zIndex: 0
              }}
            >
              {data.map((item, index) =>
                rerender ? (
                  <TableRow
                    key={item?.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      'td:not(:last-child), th:not(:last-child)': {
                        borderRight: '1px solid #EEEEEE'
                      },
                      ':hover': hover && {
                        bgcolor: 'secondary.lighter',
                        '.MuiTableCell-root': {
                          color: 'primary.dark'
                        },
                        cursor: 'pointer'
                      }
                    }}
                    onClick={() => handleRowClick(item)}
                  >
                    {columns.map(
                      ({
                        name,
                        render,
                        cellProps,
                        onClick,
                        type,
                        actionType
                      }) => {
                        if (type === tableTypes.ACTION)
                          return (
                            <TableCell
                              key={`${item?.id}action${actionType}`}
                              sx={{ px: 1 }}
                            >
                              {getActionButtons(item)}
                            </TableCell>
                          );
                        return (
                          <TableCell
                            align="left"
                            {...cellProps}
                            key={`${item?.id}${name}`}
                            onClick={onClick ? () => onClick(item) : () => {}}
                          >
                            {name === '#'
                              ? (pagination?.page || 0) *
                                  (pagination?.pageSize || 20) +
                                index +
                                1
                              : renderComponent(render, item, name, index)}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                ) : (
                  <TableRow
                    key={item.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      'td:not(:last-child), th:not(:last-child)': {
                        borderRight: '1px solid #EEEEEE'
                      },
                      ':hover': hover && {
                        bgcolor: 'secondary.lighter',
                        '.MuiTableCell-root': {
                          color: 'primary.dark'
                        },
                        cursor: 'pointer'
                      }
                    }}
                    onClick={() => handleRowClick(item)}
                  >
                    <TableCellsContainer
                      index={index}
                      item={item}
                      columns={columns}
                      pagination={pagination}
                      getActionButtons={getActionButtons}
                      getModalButton={getModalButton}
                    />
                  </TableRow>
                )
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow sx={{ height: 200 }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                  component="td"
                >
                  <NoData description={emptyDescription} />
                </Box>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {data?.length > 0 && pagination && (
        <Stack direction="row" justifyContent="center">
          {paginationAdornment?.left && (
            <Stack position="absolute" left={0}>
              {paginationAdornment?.left}
            </Stack>
          )}
          <Stack flexDirection="row" alignItems="center" position="relative">
            {loading && (
              <CircularProgress
                size={22}
                sx={{
                  position: 'absolute',
                  right: 0,
                  left: 0,
                  margin: 'auto'
                }}
              />
            )}
            <Pagination
              count={pagination?.count || 1}
              variant="outlined"
              shape="rounded"
              color="primary"
              showFirstButton
              showLastButton
              page={pagination.page + 1}
              disabled={loading}
              onChange={(e, page) => {
                if (page !== pagination.page + 1) {
                  setLoading(true);
                  setTimeout(() => {
                    scrollToRow();
                  }, 500);
                  onChangePage(e, page - 1);
                }
              }}
            />
          </Stack>
          {paginationAdornment?.right && (
            <Stack position="absolute" right={0}>
              {paginationAdornment?.right}
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
}
