import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';
import RestService from './RestService';

const statisticsByMemberService = {
  async getStatisticsByMember(params = {}) {
    try {
      const res = await new RestService()
        .setPath('client-admin/statistics/by-member')
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportStatisticsByMember(params = {}) {
    try {
      const res = await new RestService()
        .setPath('client-admin/statistics/by-member/export')
        .setConfig({ params })
        .setResponseType('blob')
        .get();
      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getPaymentDetail(id, type, params) {
    try {
      const res = await new RestService()
        .setPath(`client-admin/statistics/by-member/${id}/${type}`)
        .setConfig({ params })
        .get();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default statisticsByMemberService;
