import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import { MotionContainer, varBounceIn } from '@components/animate';
import HelmetContainer from '@components/HelmetContainer';
import NavbarFixed from '@layout/new/navbar/NavbarFixed';
import { ReactChannelIO } from 'react-channel-plugin';
import NotfoundImage from 'assets/images/404.png';
import NotfoundImagePc from 'assets/images/404_pc.png';
import useResponsive from '@hooks/useResponsive';

const RootStyle = styled(HelmetContainer)(({ theme }) => ({
  height: '100vh',
  width: '100%'
}));

export default function NotFound() {
  const isSm = useResponsive('down', 1024);
  return (
    <RootStyle title="404 Page Not Found | Workers high">
      <ReactChannelIO
        pluginKey="a421a2f2-3eb5-4190-b43d-c64163a7b0e9"
        language="ko"
        autoBoot
      >
        <NavbarFixed />
        <Box
          sx={{
            mb: '!important',
            p: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: ' column',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              width: { md: '75%', xs: '100%' },
              justifyContent: 'center',
              flexGrow: 1,
              alignItems: 'center'
            }}
          >
            <MotionContainer initial="initial" open>
              <Box
                sx={{
                  maxWidth: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  mt: { md: 20, xs: 12 },
                  gap: { md: 5, xs: 2.5 },
                  px: { xs: 2.5, md: 0 }
                }}
              >
                <motion.div variants={varBounceIn}>
                  <Typography
                    sx={{
                      fontSize: { xs: 24, md: 48 },
                      fontWeight: 700,
                      color: '#212121',
                      letterSpacing: { xs: '-0.72px' },
                      whiteSpace: 'pre-line',
                      wordBreak: 'keep-all', // Prevent breaking words
                      overflowWrap: 'break-word' // Handle long words
                    }}
                    paragraph
                  >
                    죄송합니다. 페이지를 찾을 수 없습니다.
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { xs: 14, md: 16 },
                      mt: { xs: 2.5, md: 0 },
                      letterSpacing: { xs: '-0.42px' },
                      whiteSpace: 'pre-line',
                      wordBreak: 'keep-all', // Prevent breaking words
                      overflowWrap: 'break-word', // Handle long words
                      fontWeight: 500,
                      color: '#212121'
                    }}
                  >
                    존재하지 않는 주소를 입력하셨거나, 요청하신 페이지의 주소가
                    변경, 삭제되어 찾을 수 없습니다.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 14, md: 16 },
                      fontWeight: 500,
                      mt: { xs: 1, md: 0 },
                      letterSpacing: { xs: '-0.42px' },
                      color: '#212121'
                    }}
                  >
                    입력하신 페이지의 주소가 정확한지 다시 한 번 확인해 주세요.
                  </Typography>
                </motion.div>

                <Box>
                  <Button
                    to="/"
                    size="large"
                    variant="contained"
                    sx={{
                      cursor: 'pointer !important',
                      height: 40,
                      minWidth: 178,
                      borderRadius: 0,
                      backgroundColor: '#4A515A',
                      color: '#FFFF',
                      ':hover': {
                        backgroundColor: '#434951',
                        color: '#FFFF'
                      }
                    }}
                    component={RouterLink}
                  >
                    메인으로
                  </Button>
                </Box>
              </Box>
            </MotionContainer>
          </Box>

          <Box
            sx={{
              position: 'relative',
              height: { md: '180px', xs: '100px' },
              width: '100%',
              backgroundColor: '#E7ECFF',
              zIndex: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                bottom: '97%',
                right: { md: '13%', xs: 0 }
              }}
            >
              <Box
                component="img"
                src={isSm ? NotfoundImage : NotfoundImagePc}
                sx={{
                  display: 'block',
                  maxWidth: { xs: '299px', md: '566px' },
                  maxHeight: { xs: '200px', md: '399px' }
                }}
              />
            </Box>
          </Box>
        </Box>
      </ReactChannelIO>
    </RootStyle>
  );
}
