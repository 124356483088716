import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { ReactChannelIO } from 'react-channel-plugin';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import InquiryModal from '@pages/Inquiry/InquiryServiceModal';
import { setOpenForm } from '@store/reducers/appReducer';
import Footer from './footer/Footer';
import '../index.css';
import NavbarFixed from './navbar/NavbarFixed';

const LayoutNew = () => {
  // const { boot } = useChannelIOApi();
  // useEffect(() => {
  //   boot();
  // }, []);
  const { openForm } = useSelector((state) => state.app);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (openForm) setOpenDialog(true);
  }, [openForm]);
  return (
    <>
      <Helmet>
        <meta property="og:title" content="No.1 workershigh english title" />
        <meta property="og:description" content="english description" />
        <meta property="og:image" content="/thumnail.png" />
        <meta property="og:url" content="https://www.workershigh.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <ReactChannelIO
        // 04ad9ae3-07f3-4e83-9799-685e80be8622  a421a2f2-3eb5-4190-b43d-c64163a7b0e9
        pluginKey="a421a2f2-3eb5-4190-b43d-c64163a7b0e9"
        language="ko"
        autoBoot
      >
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="space-between"
          sx={{
            height: '100%',
            minHeight: '100vh',
            backgroundColor: '#FFFFFF'
          }}
        >
          <Stack minHeight="54px">
            <NavbarFixed />
          </Stack>
          <Stack minHeight={{ md: '74vh' }} sx={{ flexGrow: 1 }}>
            <Outlet />
          </Stack>
          <Stack minHeight="184px">
            <Footer />
          </Stack>
          {openForm && (
            <InquiryModal
              open={openDialog}
              setOpen={() => {
                setOpenDialog(false);
                dispatch(setOpenForm(false));
              }}
              formType={openForm.type}
            />
          )}
        </Stack>
      </ReactChannelIO>
    </>
  );
};

export default LayoutNew;
