import { formatMoney } from '@utils/helpers';
import { Formatter } from '@utils/formatter';
import { Chip } from '@mui/material';

const { formatTime } = Formatter;

const rerender = (item, value) => {
  return (
    <span
      style={{
        textDecoration: item.refund === 'Y' ? 'line-through' : '',
        color: item.refund === 'Y' && 'gray'
      }}
    >
      {value}
    </span>
  );
};

export const columnTotalOrderHistory = (t) => [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item, index) => rerender(item, index)
  },
  {
    name: 'clientName',
    label: t('common.client'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.clientName)
  }
  , {
    name: 'regDate',
    label: t('common.dateOfTransaction'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => formatTime(item?.regDate)
  },

  {
    name: 'orderNumber',
    label: t('common.orderNumber'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.orderNumber)
  },
  {
    name: 'purchasedType',
    label: t('common.purchasedType'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => {
      let type = '';
      if (item?.purchasedType === 'DEFFERED') {
        type = t('common.DEFFERED');
      } else if (item?.purchasedType === 'INICIS') {
        type = t('common.INICIS');
      } else if (item?.purchasedType === 'WELFAREBILL') {
        type = t('common.WELFAREBILL');
      } else if (item?.purchasedType === 'INICISBILL') {
        type = t('common.INICISBILL');
      } else if (
        item?.purchasedType === 'EASYCHECK' ||
        item?.purchasedType === 'JTNET' ||
        item?.purchasedType === 'KSNET'
      ) {
        type = t('common.EASYCHECK');
      } else if (item?.purchasedType === 'DISCARD') {
        type = t('common.DISCARD');
      } else if (item?.purchasedType === 'LOSS') {
        type = t('common.LOSS');
      }
      if (item?.purchasedType === 'LOSS' || item?.purchasedType === 'DISCARD') {
        return (
          <Chip
            label={type}
            color={
              (item?.purchasedType === 'LOSS' && 'error') ||
              (item?.purchasedType === 'DISCARD' && 'secondary')
            }
          />
        );
      }
      return (
        <span style={{ color: item.refund === 'Y' && 'gray' }}>{type}</span>
      );
    }
  },

  {
    name: 'kioskLocation',
    label: t('common.minibar'),
    minWidth: 160,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.kioskLocation)
  },
  {
    name: 'isMember',
    label: t('common.member'),
    minWidth: 80,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item?.isMember === 'Y' ? 'Y' : 'N')
  },
  {
    name: 'productName',
    label: t('common.productName'),
    minWidth: 160,
    align: 'center',
    render: (item) => rerender(item, item?.productName)
  },
  {
    name: 'categoryName1',
    label: `${t('pages.curation.1st-cat')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item?.categoryName1)
  },
  {
    name: 'categoryName2',
    label: `${t('pages.curation.2nd-cat')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item?.categoryName2)
  },
  {
    name: 'categoryName3',
    label: `${t('pages.curation.3rd-cat')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item?.categoryName3)
  },
  {
    name: 'maker',
    label: t('common.manufacturer'),
    minWidth: 160,
    align: 'center',
    render: (item) => rerender(item, item?.maker)
  },
  {
    name: 'capacity',
    label: t('common.volume'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, item?.capacity)
  },
  {
    name: 'capacityType',
    label: t('common.package'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, item?.capacityType)
  },
  {
    name: 'price',
    label: t('common.unitPrice'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item?.price))
  },
  {
    name: 'quantity',
    label: t('common.quantity'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item?.quantity))
  },
  {
    name: 'discount',
    label: t('common.discountAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.discount))
  },
  {
    name: 'selectedPrice',
    label: t('common.productTotalAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.selectedPrice))
  },
  {
    name: 'totalPrice',
    label: t('common.totalAmount'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item?.totalPrice))
  },
  {
    name: 'totalAmount',
    label: t('common.totalPaymentAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.totalAmount))
  },
  {
    name: `rateUser`,
    label: t('common.userBurden'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.rateUser))
  },
  {
    name: 'rateCompany',
    label: t('common.companyBurden'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item?.rateCompany))
  }
];

export const columnDetailPayment = (t) => [
  {
    name: '#',
    label: 'No',
    minWidth: 60,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item, index) => rerender(item, index)
  },
  {
    name: 'regDate',
    label: t('common.paymentTime'),
    minWidth: 125,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatTime(item?.regDate))
  },
  {
    name: 'orderNumber',
    label: t('common.orderNumber'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, item.orderNumber)
  },
  {
    name: 'orderNumber',
    label: t('common.orderNumber'),
    minWidth: 180,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, item.orderNumber)
  },
  // {
  //   label: t('common.member'),
  //   minWidth: 80,
  //   align: 'center',
  //   isGroup: true,
  //   groupBy: 'purchaseCode',
  //   dataKey: 'index',
  //   render: (item) => rerender(item, item.memberName || item.phone)
  // },
  {
    name: 'productName',
    label: t('common.productName'),
    minWidth: 160,
    align: 'center',
    render: (item) => rerender(item, item.productName)
  },
  {
    name: 'categoryName1',
    label: `${t('pages.curation.1st-cat')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryName1)
  },
  {
    name: 'categoryName2',
    label: `${t('pages.curation.2nd-cat')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryName2)
  },
  {
    name: 'categoryName3',
    label: `${t('pages.curation.3rd-cat')}`,
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryName3)
  },
  {
    name: 'categoryNameSpecial',
    label: t('common.characterstic'),
    minWidth: 120,
    align: 'center',
    render: (item) => rerender(item, item.categoryNameSpecial)
  },
  {
    name: 'cost',
    label: t('common.cost'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.cost))
  },
  {
    name: 'price',
    label: t('common.unitPrice'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.price))
  },
  {
    name: 'quantity',
    label: t('common.quantity'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.quantity))
  },
  {
    name: 'totalPrice',
    label: t('common.totalAmount'),
    minWidth: 100,
    align: 'center',
    render: (item) => rerender(item, formatMoney(item.totalPrice))
  },
  {
    name: 'selectedPrice',
    label: t('common.selectedPrice'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item.selectedPrice))
  },
  {
    name: 'discount',
    label: t('common.discountAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item.discount))
  },
  {
    name: 'totalAmount',
    label: t('common.totalPaymentAmount'),
    minWidth: 100,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item) => rerender(item, formatMoney(item.totalAmount))
  }
];

export const searchOptions = (t) => [
  { value: 'phone', label: t('common.phoneNo') },
  { value: 'productName', label: t('common.productName') },
  { value: 'totalAmount', label: t('common.totalAmount') }
];

export const radioOptions = (t) => [
  { value: 'Total', label: t('common.total') },
  { value: 'Refund', label: t('common.refund') },
  { value: 'Loss', label: t('common.loss') },
  { value: 'Dispose', label: t('common.dispose') },
  { value: 'Payment', label: t('common.payment') }
];
