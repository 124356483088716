import React, { useState } from 'react';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  selectdisabled: {
    color: 'grey'
  },
  menuitem: {
    direction: 'rtl'
  },
  menuitemhidden: {
    display: 'none'
  }
}));
const lang = localStorage.getItem('i18nextLng')?.toLowerCase() || 'ko';
const MySelect = ({
  label,
  hideMenuItems,
  multiple,
  value,
  onChange,
  placeholder,
  data,
  sx,
  required = false,
  errMg,
  fullWidth,
  ...rest
}) => {
  const classes = useStyles();
  const [showPlaceholder, setShowPlaceholder] = useState(value === 'none');

  if (multiple) {
    return (
      <FormControl sx={{ ...sx }} error={errMg}>
        <Select
          multiple
          value={data.reduce(
            (rs, item) =>
              value?.includes(item.value) ? rs.concat([item.value]) : rs,
            []
          )}
          renderValue={(selected) =>
            data
              .reduce(
                (rs, item) =>
                  selected?.includes(item.value) ? rs.concat([item.label]) : rs,
                []
              )
              .join(', ')
          }
          onChange={onChange}
          sx={{
            bgcolor: 'common.white',
            minWidth: 180,
            width: '100%'
          }}
          size="small"
          {...rest}
        >
          {data?.length > 0 ? (
            data.map(({ value: val, label }) => (
              <MenuItem value={val} key={val}>
                <Checkbox checked={value?.indexOf(val) > -1 || false} />
                <ListItemText primary={label} />
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Item</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
  if (!label)
    return (
      <FormControl sx={{ ...sx }} error={errMg}>
        <Select
          value={value}
          onChange={onChange}
          defaultValue="none"
          onFocus={(e) => setShowPlaceholder(false)}
          onClose={(e) => setShowPlaceholder(e.target.value === undefined)}
          className={` classes.select
           ${value === 'none' ? classes.selectdisabled : null}`}
          // sx={{
          //   bgcolor: 'common.white',
          //   minWidth: 200,
          //   width: fullWidth && '100%'
          // }}
          MenuProps={{
            sx: {
              '& .Mui-selected': {
                color: '#4267EB !important',
                backgroundColor: '#FFFFFF !important',
                fontWeight: 500,
                fontSize: 16,
                whiteSpace: 'nowrap'
              }
            },
            PaperProps: {
              sx: {
                mt: 1.5,
                borderRadius: 0,
                '& .MuiTypography-root': {
                  color: '#212121',
                  fontWeight: 500,
                  fontSize: {
                    md: 14,
                    xs: 12
                  },
                  whiteSpace: 'nowrap'
                }
              }
            },

            'aria-hidden': 'false',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }
          }}
          sx={{
            bgcolor: 'common.white',
            borderRadius: 0,
            height: '40px',
            minWidth: 150,
            width: '100%',

            ...sx
          }}
          size="small"
          {...rest}
        >
          {placeholder && (
            <MenuItem
              className={`classes.menuitem ${
                !showPlaceholder ? classes.menuitemhidden : null
              }`}
              key="0"
              disabled
              sx={{
                color: '#92939A ',
                fontWeight: 400,
                fontSize: {
                  md: 14,
                  xs: 12
                }
              }}
              value="none"
            >
              <Typography
                sx={{
                  color: '#919193 !important',
                  fontWeight: { md: 400, xs: 400 },
                  fontSize: {
                    md: 14,
                    xs: 12
                  }
                }}
              >
                {placeholder}
              </Typography>
            </MenuItem>
          )}
          {data?.length > 0
            ? data.map(({ value, label }, index) => (
                <MenuItem
                  value={value}
                  key={value}
                  sx={{
                    borderBottom:
                      index !== data.length - 1 && '1px solid #DEE2E6',
                    px: 2,
                    py: 1
                  }}
                >
                  {label}
                </MenuItem>
              ))
            : !hideMenuItems && <MenuItem>No Item</MenuItem>}
        </Select>
        {errMg && (
          <span
            style={{
              color: 'red',
              fontSize: 12
            }}
          >
            * {errMg}
          </span>
        )}
      </FormControl>
    );
  return (
    <FormControl
      required={required}
      error={errMg}
      sx={{
        flexDirection: { md: 'column', xs: 'column' },
        gap: 1.5,
        width: fullWidth && '100%'
        // alignItems: { md: 'baseline' }
      }}
    >
      <Typography
        sx={{
          fontSize: { md: lang === 'ko' ? 14 : 12, xs: 14 },
          fontWeight: 600,
          color: '#212121',
          width: lang === 'ko' ? 'auto' : '130px'
        }}
      >
        {required && (
          <span style={{ color: 'red', fontSize: { md: 20, xs: 14 } }}>*</span>
        )}
        {label}
      </Typography>

      <Stack width="100%">
        <Select
          value={value}
          onChange={onChange}
          defaultValue="none"
          onFocus={(e) => setShowPlaceholder(false)}
          onClose={(e) => setShowPlaceholder(e.target.value === undefined)}
          className={` classes.select
         ${value === 'none' ? classes.selectdisabled : null}`}
          MenuProps={{
            sx: {
              '& .Mui-selected': {
                color: '#4267EB',
                backgroundColor: '#FFFFFF',
                fontWeight: 500,
                fontSize: 16,
                whiteSpace: 'nowrap'
              }
            }
          }}
          sx={{
            bgcolor: 'common.white',
            borderRadius: 0,
            height: '40px',
            minWidth: 200,
            width: '100%',
            '.MuiTypography-root': {
              color: '#373737',
              fontWeight: 500,
              fontSize: {
                md: 14,
                xs: 12
              },
              whiteSpace: 'nowrap',
              ...sx?.label
            },
            ...sx
          }}
          {...rest}
        >
          {placeholder && (
            <MenuItem
              className={`classes.menuitem ${
                !showPlaceholder ? classes.menuitemhidden : null
              }`}
              key="0"
              disabled
              sx={{
                color: '#92939A !important',
                fontWeight: 400,
                fontSize: {
                  md: 14,
                  xs: 12
                }
              }}
              value="none"
            >
              <Typography
                sx={{
                  color: '#92939A !important',
                  fontWeight: 400,
                  fontSize: {
                    md: 14,
                    xs: 12
                  }
                }}
              >
                {placeholder}
              </Typography>
            </MenuItem>
          )}
          {data?.length > 0 ? (
            data.map(({ value, label }, index) => (
              <MenuItem
                value={value}
                key={value}
                sx={{
                  borderBottom:
                    index !== data.length - 1 && '1px solid #DEE2E6',
                  px: 2,
                  py: 1
                }}
              >
                {label}
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Item</MenuItem>
          )}
        </Select>
      </Stack>
      {errMg && (
        <span
          style={{
            color: 'red',
            fontSize: 12
          }}
        >
          * {errMg}
        </span>
      )}
    </FormControl>
  );
};

export default MySelect;
