import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@components/buttons/LoadingButton';

export default function MyDialog({
  open,
  setOpen,
  title,
  children,
  onOk,
  onCancel,
  okTitle,
  cancelTitle,
  hasCancelButton,
  isAlert = false,
  hasCloseButton,
  hideDialogButton = false,
  sx,
  subTitle,
  disabled,
  onClose,
  content,
  idScript,
  ...rest
}) {
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          zIndex: 0,
          borderRadius: { md: '30px', xs: '20px' },
          minWidth: { md: '400px', xs: '260px' },
          maxWidth: { md: '600px', xs: '300px' },
          p: { md: '40px 30px', xs: '20px 30px' },
          ...sx
        }
      }}
      slotProps={{
        backdrop: { sx: { bgcolor: '#343434', opacity: '0.6 !important' } }
      }}
      {...rest}
    >
      <Stack sx={{ pb: { md: '40px', xs: '24px' } }}>
        <DialogTitle
          sx={{
            color: 'text.primary',
            fontSize: { md: '24px', xs: '16px' },
            textAlign: 'center',
            p: 0
          }}
        >
          {title}
          {!isAlert && hasCloseButton ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 22,
                top: 22,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          {subTitle && typeof subTitle === 'function' ? (
            subTitle
          ) : (
            <Typography sx={{ fontSize: 13 }}>{subTitle}</Typography>
          )}
        </DialogTitle>
        {content && (
          <DialogContentText
            sx={{
              fontSize: { md: '20px', xs: '14px' },
              color: 'primary.main',
              textAlign: 'center',

              mt: { md: '16px', xs: '8px' }
            }}
          >
            {content}
          </DialogContentText>
        )}
        {children && (
          <DialogContent sx={{ p: 0, mt: { md: '16px', xs: '8px' } }}>
            {children}
          </DialogContent>
        )}
      </Stack>
      {!hideDialogButton && (
        <DialogActions
          sx={{
            justifyContent: 'center',
            p: 0
          }}
        >
          {hasCancelButton && (
            <Button
              autoFocus
              variant="outlined"
              onClick={onCancel || handleClose}
              sx={{
                fontSize: { md: 20, xs: 13 },
                minWidth: { md: '150px', xs: '116px' },
                minHeight: { md: '60px', xs: '44px' }
              }}
            >
              {cancelTitle || '취소'}
            </Button>
          )}
          <LoadingButton
            id={idScript}
            variant="contained"
            onClick={onOk || handleClose}
            sx={{
              fontSize: { md: 20, xs: 13 },
              minWidth: { md: '150px', xs: '116px' },
              minHeight: { md: '60px', xs: '44px' }
            }}
            disabled={disabled}
          >
            {okTitle || '확인'}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
