const breakpoints = {
  values: {
    xs: 0,
    sm: 769,
    md: 1025,
    lg: 1920,
    xl: 2100
  }
};

export default breakpoints;
