import MyTable from '@components/MyTable';
import React from 'react';
import { paymentColumns } from '../enhance';

const PaymentHistory = ({ data }) => {
  const columns = paymentColumns;
  return <MyTable data={data} columns={columns} rerender />;
};

export default PaymentHistory;
