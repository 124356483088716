import { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Lottie from 'lottie-react';
import * as animationData from './NasaLoader.json';

// loader style
const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2001,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

// ==============================|| Loader ||============================== //

const Loader = () => {
  const lottieRef = useRef();
  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(1.8);
    }
  }, []);
  return (
    <LoaderWrapper>
      <Lottie
        lottieRef={lottieRef}
        animationData={animationData}
        loop
        style={{ width: '150px', height: '140px' }}
      />
    </LoaderWrapper>
  );
};

export default Loader;
