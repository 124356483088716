import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, MenuItem, Stack } from '@mui/material';
import minibarServices from '@services/minibar';
import MyAutocomplete from '../MyAutocomplete';

const MinibarAutoCompleteV2 = ({
  setMinibar,
  minibar,
  disabled,
  client,
  label = true,
  sx,
  ...rest
}) => {
  const { t } = useTranslation();

  const [itemList, setItemList] = useState([]);
  const [itemInput, setItemInput] = useState('');

  const getItems = async () => {
    const additionalPayload = {
      clientCode: client?.clientCode || null,
      isOption: Boolean(client?.clientCode)
    };
    const { data } = await minibarServices.getListMinibar({
      keyword: itemInput,
      page: 0,
      ...additionalPayload
    });
    if (!data?.items) return;

    setItemList(data.items);
  };

  const onInputChange = (e, val, reason) => {
    return reason === 'input' && setItemInput(val);
  };

  const onChange = (e, val) => {
    if (!val) setItemInput('');
    setMinibar(val);
  };
  const [loadMore, setLoadMore] = useState(false);
  const [endLoad, setEndLoad] = useState(true);
  const [page, setPage] = useState(0);
  useEffect(() => {
    setItemList([]);
    setEndLoad(false);
    setPage(0);
    getItems();
  }, [itemInput]);

  useEffect(() => {
    if (client) getItems();
    else {
      setMinibar(null);
      setItemList([]);
    }
  }, [client]);
  const onLoadMore = async () => {
    if (!endLoad && itemList?.length > 19) {
      setLoadMore(true);
      const additionalPayload = {
        clientCode: client?.clientCode || null,
        isOption: Boolean(client?.clientCode)
      };
      const { data } = await minibarServices.getListMinibar({
        keyword: itemInput,
        page: page + 1,
        ...additionalPayload
      });
      setPage(page + 1);
      setEndLoad(!data?.items?.length);
      if (data?.items && data?.items?.length > 0) {
        setItemList(itemList.concat(data?.items));
      }
      setLoadMore(false);
    }
    if (itemList?.length < 20) {
      setEndLoad(true);
      setLoadMore(false);
    }
    return true;
  };
  const observer = useRef();
  const lastOptionElementRef = (node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && onLoadMore) {
        setTimeout(() => {
          onLoadMore();
        }, 300);
      }
    });
    if (node) observer.current.observe(node);
  };
  return (
    <MyAutocomplete
      label={label && t('common.minibar')}
      textFieldProps={{ placeholder: t('common.master') }}
      value={
        minibar?.minibarNickname && minibar?.minibarCode
          ? minibar
          : itemList?.filter(
              (i) => i?.minibarCode === minibar?.minibarCode
            )?.[0] || null
      }
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => option?.minibarNickname}
      onChange={onChange}
      onInputChange={onInputChange}
      options={itemList}
      disabled={disabled}
      onClose={() => {
        if (!minibar) {
          setItemInput('');
        }
      }}
      sx={{
        '.MuiInputBase-root': {
          bgcolor: !disabled ? 'common.white' : '#f2f2f2'
        },
        ...sx
      }}
      ListboxProps={{ style: { overflow: 'visible' } }}
      renderOption={(option, { ref }) => {
        const { key, id, ...restOption } = option;
        const isLastOption =
          Number(option?.['data-option-index']) + 1 === itemList.length;
        return (
          <>
            <MenuItem
              {...restOption}
              key={id}
              sx={{ overflowX: 'hidden' }}
              ref={isLastOption ? lastOptionElementRef : ref}
            >
              {key}
            </MenuItem>
            {isLastOption && !endLoad && (
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                visibility={loadMore && 'visible'}
              >
                <CircularProgress size={15} />
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    letterSpacing: '1px',
                    margin: '0 5px'
                  }}
                >
                  Loading...
                </span>
              </Stack>
            )}
          </>
        );
      }}
      {...rest}
    />
  );
};

export default MinibarAutoCompleteV2;
