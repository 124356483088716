import MyDialog from '@components/MyDialog/NewDialog';
import React from 'react';
import { formTypes } from '@constants/utils';
import i18n from '@utils/i18n';
import SwipeableEdgeDrawer from '@components/SwipebleDrawer';
import useResponsive from '@hooks/useResponsive';
import { Stack, Typography } from '@mui/material';
import ConsultationInquiryForm from './forms/ConsultationInquiryForm';
import ServiceInquiryForm from './forms/ServiceIntroduction';

const contactT = i18n.getFixedT(null, null, 'pages.contactForm');
const InquiryModal = ({ open, setOpen, formType }) => {
  // const getFormTitle = (type) => {
  //     switch (type) {
  //         case formTypes.ADS:
  //             return `${contactT(`form.${formTypes.ADS}`)} ${contactT(`form.register` )}`

  //         default:
  //             break;
  //     }
  // }
  const isSm = useResponsive('down', 1024);
  if (isSm)
    return (
      <SwipeableEdgeDrawer
        open={open}
        setOpen={() => setOpen(false)}
        title={
          formType === formTypes.CONSULTATION ? (
            `${contactT(`form.${formTypes.CONSULTATION}`)}`
          ) : (
            <Typography
              sx={{
                color: '#212121',
                fontSize: '24px !important',
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                fontWeight: 700,
                flexWrap: { xs: 'wrap', md: 'nowrap' },
                gap: 0.5,
                '& span': { color: '#244EE2', whiteSpace: 'nowrap' }
              }}
              dangerouslySetInnerHTML={{
                __html: contactT(`form.${formType}-form-title`)
              }}
            />
          )
        }
        subTitle={`${contactT(`form.${formType}-form-subtitle`)}`}
      >
        <Stack sx={{ pb: 5 }}>
          {formType === formTypes.CONSULTATION ? (
            <ConsultationInquiryForm
              turnBack={() => setOpen(false)}
              closeModal={() => setOpen(false)}
            />
          ) : (
            <ServiceInquiryForm
              turnBack={() => setOpen(false)}
              closeModal={() => setOpen(false)}
            />
          )}
        </Stack>
      </SwipeableEdgeDrawer>
    );
  return (
    <MyDialog
      open={open}
      setOpen={() => setOpen(false)}
      hasCloseButton
      hideDialogButton
      sx={{ width: '600px' }}
      title={
        formType === formTypes.CONSULTATION ? (
          `${contactT(`form.${formTypes.CONSULTATION}`)}`
        ) : (
          <Typography
            sx={{
              color: '#212121',
              fontSize: '24px !important',
              textAlign: 'left',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontWeight: 700,
              letterSpacing: '-0.72px',
              whiteSpace: 'nowrap',
              gap: 0.5,
              '& span': { color: '#244EE2' }
            }}
            dangerouslySetInnerHTML={{
              __html: contactT(`form.${formType}-form-title`)
            }}
          />
        )
      }
      subTitle={`${contactT(`form.${formType}-form-subtitle`)}`}
    >
      {formType === formTypes.CONSULTATION ? (
        <ConsultationInquiryForm
          turnBack={() => setOpen(false)}
          closeModal={() => setOpen(false)}
        />
      ) : (
        <ServiceInquiryForm
          turnBack={() => setOpen(false)}
          closeModal={() => setOpen(false)}
        />
      )}
    </MyDialog>
  );
};

export default InquiryModal;
