import { Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';

const LoadingButton = (props) => {
  const { onClick, children, disabled, id, ...rest } = props;
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onClick();
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <Button
      id={id}
      startIcon={loading && <CircularProgress size={20} />}
      disabled={disabled || loading}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default React.memo(LoadingButton);
