import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

const Footer = ({ open, drawerWidth }) => {
  return (
    <Box
      component="footer"
      sx={(theme) => ({
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),

        px: '30px',
        py: '17px',
        borderTop: '1px solid #eeeeee',
        position: 'fixed',
        bottom: 0,
        zIndex: 1200,
        bgcolor: 'common.white',
        height: '52px',

        marginLeft: 0,
        width: '100%',

        ...(open && {
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
          })
        })
      })}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ color: '#979797', fontSize: '11px' }} noWrap>
          Copyright © 2021 Worker&apos;s High. All rights reserved.
        </Typography>
        <Typography sx={{ color: '#979797', fontSize: '11px' }} noWrap>
          Version 1.0.0
        </Typography>
      </Stack>
    </Box>
  );
};

export default Footer;
