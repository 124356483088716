import policyTable from 'assets/images/policy.png';
import payment from 'assets/images/payment.png';

export const termsAndPolicyDateOptions = [{ value: 1, label: '2024.07.10' }];
export const terms = {
  title: '서비스 이용약관',
  items: [
    {
      title: '제 1조(목적)',
      paragraphs: [
        {
          title:
            '본 이용약관은 (주)워커스하이 (이하 "워커스하이")가 제공하는 워커스하이 서비스 및 관련 제반 서비스(이하 “서비스”)의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.'
        }
      ]
    },
    {
      title: '제 2조(용어의 정의)',
      paragraphs: [
        {
          title: '본 약관에서 사용하는 용어의 정의는 다음과 같습니다.',
          body: [
            {
              value:
                '1. 서비스 : "회사"가 제공하는  OFFRESH, WORKERSMARKET 등 관련한 제반 서비스를 의미합니다.'
            },
            {
              value:
                '2. 회원 : “회사”와 “서비스”이용 계약을 체결하고 ”회사”로부터 “서비스” 이용 자격을 부여 받은 개인 또는 법인을 의미합니다.'
            }
          ]
        },
        {
          title:
            '본 약관에서 사용하는 용어의 정의는 위에서 정한 것을 제외하고는 관련법령에서 정하는 바에 의하며, 관련 법령에서 정하지 않는 것은 일반적인 상관례에 의합니다.'
        }
      ]
    },
    {
      title: '제 3조(용어의 명시와 개정)',
      paragraphs: [
        {
          title:
            '“회사”는 본 약관의 내용과 상호, 영업소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 “회원”이 쉽게 알 수 있도록 “서비스” 초기화면에 게시하거나 기타의 방법으로 “회원”에게 공지합니다.'
        },
        {
          title:
            '“회사”는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.'
        },

        {
          title:
            '“회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 개정약관의 적용일자 7일전부터 “회원”에게 통보하거나 홈페이지 초기화면에 공지하도록 합니다. 다만, “회원”의 권리 또는 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터 공지합니다.',
          body: [
            {
              value:
                '(단, 회원의 권리 또는 의무에 중요한 영향을 주지 않는 변경, 법령의 개정 후 법령을 준수하기 위한 변경, 오탈자 수정 또는 표현의 변경은 사전 공지 없이 즉시 발효될 수 있습니다.)'
            }
          ]
        }
      ]
    },
    {
      title: '제 4조(약관 외 준칙)',
      paragraphs: [
        {
          title:
            '이 약관에서 정하지 아니한 사항이나 해석에 대해서는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등의 관계법령에 따릅니다.'
        }
      ]
    },
    {
      title: '제 5조(이용계약의 성립)',
      paragraphs: [
        {
          title:
            '“회사”와 “회원” 사이의 “서비스” 이용계약(이하 "이용계약"이라 함)은 “서비스”를 이용하고자 하는 자(이하 "서비스이용신청자"라 함)가 이용약관과 개인정보 수집 및 이용 등에 동의를 한 다음 "서비스" 이용 신청을 하고, 그에 대해 “회사”가 이용 승낙을 함으로써 체결됩니다.'
        },
        {
          title:
            '"서비스이용신청자"는 이용 계약 시 실명 및 실제 정보를 입력하여 본인인증 절차를 진행하여야 하며, 이를 위반한 회원은 본 약관에서 정한 권리를 주장할 수 없고, 서비스 이용에 제한을 받을 수 있습니다.'
        },
        {
          title:
            '"회사"는 "서비스이용신청자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, "회사"는 다음 각 호에 해당하는 이용신청에 대하여는 승낙을 거절하거나 사후에 이용계약"을 해지할 수 있습니다.',
          body: [
            {
              value:
                '1. "회원"이 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우'
            },
            {
              value: '2. 실명이 아니거나 타인의 명의를 이용한 경우'
            },
            {
              value:
                '3. 허위의 정보를 기재하거나, “회사”가 제시하는 내용을 기재하지 않은 경우'
            },
            {
              value:
                '4. 신용정보의 이용 및 보호에 관한 법률에 의한 신용불량자로 등록되어 있는 경우'
            },
            { value: '5. 만 14세 미만 아동의 신청의 경우' },
            {
              value:
                '6. 자신 또는 타인의 영리를 추구할 목적으로 "서비스"를 이용하고자 하는 경우'
            },
            {
              value:
                '7. 위법, 불법행위 등 부정한 용도로 "서비스"를 이용하고자 하는 경우'
            },
            { value: '8. "회사"의 다른 서비스 이용 요금을 체납하고 있는 경우' },
            {
              value:
                '9. 과거 "서비스" 이용 요금의 연체 혹은 불량 사용 이력이 있는 경우'
            },
            {
              value:
                '10. "서비스이용신청자"의 귀책사유로 인하여 승낙이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우'
            },
            {
              value:
                '11. 기타 관련 법령에 위배되거나 세부지침 등 회사가 정한 기준에 반하는 경우'
            }
          ]
        },
        {
          title:
            '“회사”는 “서비스” 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.'
        },
        {
          title:
            '이용신청의 승낙을 하지 아니하거나 유보한 경우 “회사”는 원칙적으로 이를 “서비스이용신청자”에게 알리도록 합니다.'
        },
        {
          title:
            '"이용계약"의 성립 시기는 "회사"가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.'
        }
      ]
    },
    {
      title: '제 6조(개인정보의 보호 및 관리)',
      paragraphs: [
        {
          title:
            '“회사”는 관계 법령이 정하는 바에 따라 계정정보를 포함한 “회원”의 개인정보를 보호하기 위하여 노력합니다. “회원”의 개인정보 보호 및 사용에 대해서는 관련 법령 및 “회사”의 개인정보처리방침이 적용됩니다.'
        },
        {
          title:
            '“회사”는 “회원”의 귀책사유로 인하여 노출된 “회원”의 계정정보를 포함한 모든 정보에 대해서는 일체의 책임을 지지 않습니다.'
        }
      ]
    },
    {
      title: '제 7조(회원정보의 제공 및 변경)',
      paragraphs: [
        {
          title:
            '“회원”은 “서비스”에서 제공하는 관리화면을 통하여 언제든지 본인의 정보를 열람하고 수정할 수 있습니다.'
        },
        {
          title:
            '“회원”은 이용 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 “회사”가 정한 별도의 양식 및 방법으로 변경사항을 알려야 합니다.'
        },
        {
          title:
            '“회원”이 자신의 정보에 대한 변경사항을 알리지 않아 발생한 불이익에 대하여 "회사"는 책임을 부담하지 않습니다.'
        }
      ]
    },
    {
      title: '제 8조(회원에 대한 통지)',
      paragraphs: [
        {
          title:
            '“회사”가 “회원”에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 “회원”이 지정한 이메일, “서비스” 내 알림 메시지 또는 전자 게시물 등으로 할 수 있습니다.'
        },
        {
          title:
            '“회사”는 “회원” 전체에 대한 통지의 경우 7일 이상 “회사”의 홈페이지에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.'
        }
      ]
    },
    {
      title: '제 9조(서비스의 제공)',
      paragraphs: [
        {
          title:
            '"회원"은 "회사"가 제공하는 "서비스"를 본 약관, 운영정책 등 회사가 정한 규칙에 따라 이용할 수 있습니다.'
        },
        {
          title:
            '"회사"는 "서비스"의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 페이지에 공지한 바에 따릅니다.'
        }
      ]
    },
    {
      title: '제 10조(서비스의 중단)',
      paragraphs: [
        {
          title:
            '"회사"는 다음 각 호의 어느 하나에 해당하는 경우에 "서비스" 제공을 중단할 수 있습니다.',
          body: [
            {
              value:
                '1. “서비스”를 위한 설비의 보수 등 공사로 인해 부득이한 경우'
            },
            {
              value:
                '2. “서비스” 업그레이드 및 “서비스” 유지 보수 등을 위해 필요한 경우'
            },
            {
              value:
                '3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중단했을 경우'
            },
            {
              value:
                '4. 천재 지변, 국가 비상사태 등 불가항력적 사유가 있는 경우'
            },
            {
              value:
                '5. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 "서비스" 이용에 지장이 있는 경우'
            },
            { value: '6. 그 밖에 위 각 호에 준하는 사유가 있는 경우' }
          ]
        },
        {
          title:
            '서비스의 전부 또는 일부가 중단되는 경우 회원에게 공지 또는 통지합니다. 다만, "회사"의 고의, 과실이 없는 해킹, 사고 등 예측할 수 없거나 통제할 수 없는 사유로 인해 사전 공지 또는 통지가 불가능한 경우에는 사후에 통지를 할 수 있습니다.'
        }
      ]
    },
    {
      title: '제 11조(서비스의 변경)',
      paragraphs: [
        {
          title:
            '"회사"는 운영상, 경영상, 기술상의 필요에 따라 제공하고 있는 "서비스"의 전부 또는 일부를 변경하거나 종료할 수 있습니다. 변경의 경우에는 변경사유, 변경내용, 제공일자 등을 변경 7일 전에, 종료의 경우에는 종료 30일 전에 "서비스" 초기화면에 해당 사실을 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는 경우에는 사후에 지체 없이 이를 공지할 수 있습니다.'
        },
        {
          title:
            '"회사"는 "서비스"의 내용 또는 그 이행수준 변경 시 "회원"에게 불리하거나 변경 내용이 중요한 경우에는 "회원"의 동의를 얻어야 한다.'
        },
        {
          title:
            '"회사”는 본 약관 및 관련법령에 특별한 규정이 없는 한 “서비스”의 변경, 내용수정 및 종료로 인하여 “회원”이 입은 손해에 대해 어떠한 책임도 지지 않습니다.'
        }
      ]
    },
    {
      title: '제 12조(정보의 제공 및 광고의 게재)',
      paragraphs: [
        {
          title:
            '"회사"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 "회사"의 홈페이지에 게시할 수 있습니다. 다만, "회원"은 관련 법령에 따른 거래 관련 정보, 고객센터 답변 등의 경우 이메일로도 수신 받을 수 있습니다.'
        },
        {
          title:
            '“회사”는 관계법령에 따라 적법한 절차를 통해 “회원”에게 광고를 제공할 수 있습니다.'
        },
        {
          title:
            '“회사”는 “서비스” 이용 시 노출되는 공간 또는 타 광고 사업자가 운영하는 매체에 “회사” 또는 제3자의 상품 또는 “서비스”에 대한 광고를 게재할 수 있습니다.'
        },
        {
          title:
            '"회원"이 제3자가 주체인 제2항의 광고에 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해서 "회사"는 어떠한 책임도 부담하지 않습니다.'
        }
      ]
    },
    {
      title: '제 13조(이용제한 등)',
      paragraphs: [
        {
          title:
            '"회사"는 "회원"이 본 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 해당 "회원"에 대하여 상당한 기간을 정하여 그러한 행위를 중단하거나 시정할 것을 통지하고, 그러한 행위가 시정되지 아니한 경우에는 해당 "회원"의 본 "서비스" 이용을 일시 또는 영구적으로 차단 및 중지하고 이용계약을 해지할 수 있습니다.'
        },
        {
          title:
            '"회사"는 제1항의 규정에도 불구하고, "회원"이 본 약관 제 16조(“회원”의 의무) 제 2항에 따라 금지되는 행위를 한 경우, 또는 "회원"이 본 약관의 의무를 중대하게 반복적으로 위반하였거나, "회사"가 관련된 사정 및 상황을 기초로 그러한 것으로 합리적으로 판단하는 경우, 해당 "회원"에 대한 시정기간의 부여 없이 즉시 해당 "회원"의 서비스 이용을 차단 및 중지하고 이용계약을 해지할 수 있습니다.'
        },
        {
          title:
            '본 조에 따라 "회원"의 귀책으로 서비스 이용의 차단, 중지 및 이용계약 해지가 이루어진 경우, "서비스" 이용을 통해 획득한 혜택 등도 모두 소멸되며 "회사"는 이에 대해 별도로 보상하지 않습니다.'
        },
        {
          title:
            '"회사"가 제 1항에 따라 "서비스" 이용을 제한하거나 계약을 해지하는 경우 “회사”는 본 약관 제8조(“회원”에 대한 통지)에서 정한 방법으로 “회원에게 통지합니다. 다만, “회사”가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.'
        },
        {
          title:
            '“회원”은 “회사”의 이용제한 조치 등에 대하여 “회사”가 정한 절차에 따라 이의신청을 할 수 있습니다. “회원”의 이의 사유가 정당하다고 인정되는 경우 “회사”는 즉시 “회원”의 “서비스” 이용을 재개합니다.'
        }
      ]
    },
    {
      title: '제 14조("회원"의 계약해제, 해지 등)',
      paragraphs: [
        {
          title:
            '"회원"은 언제든지 "서비스" 탈퇴 신청을 할 수 있으며, "회사"는 관련 법령 등에서 정하는 바에 따라 이를 즉시 처리하여야 합니다.'
        },
        {
          title:
            '이용계약이 해지되면 "회원"의 모든 정보는 탈퇴 시점 이후 바로 삭제되며 복구할 수 없습니다. 단, 다음 각 호에 해당하는 경우는 삭제되지 않습니다.',
          body: [
            {
              value:
                '1. 서명 완료된 문서 및 각종 정보(단, 서명 요청자 및 서명 참여자 전원의 동의가 있을 경우 삭제 가능)'
            },
            {
              value:
                '2. 관련법령 및 회사의 개인정보 처리방침에서 정한 바에 따라 특별히 회원과 관계된 정보를 저장해야할 때'
            }
          ]
        },

        {
          title:
            '"서비스" 이용계약의 해지는 고객의 서비스 해지 신청 및 회사의 승낙에 의해 성립하게 되고, 환불할 금액이 있는 경우 환불도 이루어 지게 됩니다. 다만 각 개별 유료서비스에서 본 약관과 다른 계약해지 방법 및 효과를 규정하고 있는 경우 각 개별약관의 규정에 따릅니다.'
        }
      ]
    },
    {
      title: '제 15조("회사"의 의무)',
      paragraphs: [
        {
          title:
            '“회사”는 본 약관 및 관련 법령에서 금지하는 행위 및 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적인 “서비스”의 제공을 위하여 최선을 다하여 노력합니다.'
        },
        {
          title:
            '“회사”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록 신용정보를 포함한 개인정보 보호를 위한 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.'
        },

        {
          title:
            '“회사”는 “회원”으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 신속하게 처리하여야 합니다. 다만, 처리에 장기간이 소요되는 경우 “회원”에게 게시판 또는 전자우편 등을 통하여 지체 사유를 안내하고 처리과정 및 처리결과를 전달합니다.'
        },
        {
          title:
            '“회사”는 “이용계약”의 체결, 계약사항의 변경 및 해지 등 “회원”과의 계약관련 절차 및 내용 등에 있어 “회원”에게 편의를 제공하도록 노력합니다.'
        },
        {
          title:
            '"회사"는 다음 각 호의 사항을 "서비스" 홈페이지에 "회원"이 알기 쉽게 표시합니다.',
          body: [
            { value: '1. "서비스"의 명칭 또는 제호' },
            {
              value:
                '2. "서비스"의 내용, 이용방법, 이용요금, 결제 방법 및 기타 이용 조건'
            },
            { value: '3. 이용 가능 기기 및 이용에 필요한 최소한의 기술 사양' }
          ]
        }
      ]
    },

    // 16
    {
      title: '제 16조("회원"의 의무)',
      paragraphs: [
        {
          title:
            '“회원”은 “회사”가 제공하는 “서비스”를 이용함에 있어 관계법령, 본 약관의 규정, 개인정보처리방침, 이용안내 및 “서비스” 상에 공지한 주의사항, “회사”가 통지하는 사항을 준수할 의무가 있습니다.'
        },

        {
          title:
            '“회원”은 “회사”에서 제공하는 “서비스”를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각 호에 해당하는 행위를 해서는 안됩니다.',
          body: [
            {
              value:
                '1. 가입신청 또는 정보 변경을 목적으로 “회사”에 정보 등록 시 실명이 아닌 정보 또는 다른 사람의 정보를 사용하거나 허위 사실을 기재하는 행위'
            },
            {
              value:
                '2. 본 "서비스" 이용을 통하여 취득한 정보를 "서비스" 이용 목적 외 다른 목적으로 활용하는 행위'
            },
            {
              value:
                '3. 본 "서비스"를 회원의 "서비스" 이용 목적 또는 기타 "회사"가 명시적으로 고지 및 안내하는 이용목적 이외의 다른 목적으로 이용하는 행위'
            },
            {
              value:
                '4. “회사” 및 제3자의 명예를 훼손하거나 업무를 방해하는 행위 또는 “회사” 및 제 3자에게 손해를 가하는 행위'
            },
            {
              value:
                '5. “회사” 또는 제3자의 지적재산권, 초상권, 인격권 등 법적으로 보호되는 권리를 침해하는 행위'
            },
            {
              value:
                '6. 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위'
            },
            {
              value:
                '7. 타인의 명의 또는 정보, 다른 "회원"의 계정 및 비밀번호 등을 서비스 가입, 이용 및 결제 등의 목적으로 도용하거나 기타 부정하게 사용하는 행위'
            },
            {
              value:
                '8. 회사의 직원이나 운영자를 가장, 사칭하거나 또는 타인의 명의를 도용하여 문서를 등록하거나 메일을 발송하는 행위'
            },
            {
              value:
                '9. “서비스”에 게시된 각종 정보를 무단 변경, 삭제, 배포하는 행위'
            },
            {
              value:
                '10. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위'
            },
            {
              value:
                '11. “회사”의 동의 없이 영리, 영업, 광고, 정치활동, 불법선거운동 등을 목적으로 “서비스”를 이용하는 행위'
            },
            {
              value:
                '12. 음란하고 선정적인 사진이나 글 등을 게재하거나 공공질서나 미풍양속에 저해되는 행위'
            },
            {
              value:
                '13. 기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및 법령에 위배되는 행위'
            },
            {
              value:
                '14. 불법 및 악성프로그램, 해킹 또는 이와 유사한 방법으로 본 "서비스"의 운영을 방해하는 행위'
            },
            {
              vlaue:
                '15. 알려지거나 알려지지 않은 버그를 악용하여 정해진 목적과 방법에 벗어나도록 “서비스”를 이용하는 행위'
            },
            {
              value:
                '16. 기타 "회사"가 "서비스" 운영상 부적절하다고 판단하는 행위'
            }
          ]
        },
        {
          title:
            '“회원”은 “회사” 홈페이지 상의 공지사항 및 이용약관의 수정사항 등을 확인하고 이를 준수할 의무가 있으며 기타 “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다.'
        },
        {
          title:
            '“회원”의 계정에 관한 관리 책임은 “회원”에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.'
        },
        {
          title:
            ' “회사”는 제1항, 제2항 및 다음 각 호의 어느 하나에 해당하는 행위의 구체적인 유형을 운영 정책에서 정할 수 있으며, “회원”은 이를 준수할 의무가 있습니다.',
          body: [
            { value: '1. “회원”의 “회원ID”, “비밀번호”의 정함에 대한 제한' },
            { value: '2. “서비스” 이용 방법에 대한 제한' },
            {
              value:
                '3. 기타 “회원”의 “서비스” 이용에 대한 본질적인 권리를 침해하지 않는 범위 내에서 “회사”가 운영상 필요하다고 인정되는 사항'
            }
          ]
        }
      ]
    },

    //  17
    {
      title: '제 17조(이용요금)',
      paragraphs: [
        {
          title:
            '"서비스" 이용요금의 세부 내역 및 그 변경사항은 본 약관 제 15조(“회사”의 의무) 5항에 따라 회사의 홈페이지에 명기합니다.'
        },

        {
          title:
            '“회원”은 정해진 납부일자에 이용요금을 결제해야 하며 이를 결제하지 못할 경우에는 이용계약이 해지될 수 있습니다.'
        },
        {
          title:
            '이용요금의 변경 내용은 변경 내용 적용일부터 모든 회원에게 적용됩니다. 다만, 이용요금이 변경되더라도 변경 시점 기준 진행중인 계약 기간에 대해서는 특별한 사정이 없는 한 소급 적용되지 않습니다.'
        },
        {
          title:
            '"서비스"의 이용 요금에 관하여 "회사"와 "회원" 간 별도 약정이 있을 경우, 해당 약정이 본 약관에 우선하여 적용됩니다.'
        }
      ]
    },
    // 18
    {
      title: '제 18조(손해배상)',
      paragraphs: [
        {
          title:
            '"회사"가 제공하는 "유료서비스"의 결함 등에 의하여 "회사"의 귀책사유로 "회원"에게 피해가 발생한 경우, "회사"는 "회원"에게 실제 발생한 손해만을 배상합니다.'
        },

        {
          title:
            '"회원"이 본 약관의 의무를 위반함으로 인하여 "회사"에 손해를 입힌 경우 또는 "회원"이 "서비스"의 이용과 관련하여 "회사"에 손해를 입힌 경우 회원은 "회사"에 대하여 손해를 배상하여야 합니다.'
        },
        {
          title:
            '"회원"이 "서비스"를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한 행위로 "회사"가 당해 이용자 외의 제3자로부터 손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당해 "회원"은 자신의 책임과 비용으로 "회사"를 면책시켜야 하며, "회사"가 면책되지 못한 경우 당해 이용자는 그로 인하여 "회사"에 발생한 모든 손해를 배상할 책임이 있습니다.'
        }
      ]
    },
    // 19
    {
      title: '제 19조(권리의 귀속)',
      paragraphs: [
        {
          title:
            '"서비스"에 대한 저작권 및 기타 지식재산권은 회사에 귀속됩니다.'
        },

        {
          title:
            '"회사"는 "회원"에게 본 약관을 포함하여 "회사"가 사전에 정하여 공지 또는 통지한 이용조건에 따라 "서비스"를 이용할 수 있는 라이센스만을 부여하며, "회원"은 이를 양도 또는 판매하거나, 담보로 제공하는 등 처분행위를 하여서는 안됩니다.'
        }
      ]
    },
    // 20
    {
      title: '제 20조(면책)',
      paragraphs: [
        {
          title:
            '"회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.'
        },

        {
          title:
            '"회사"는 회원의 귀책사유로 인한 "서비스" 중지 또는 이용의 장애, 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에는 책임이 면제됩니다.'
        },
        {
          title:
            '“회사”는 “회원”이 “서비스”와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않으며, 이로 인한 손해를 배상하지 않습니다.'
        },
        {
          title:
            '“회사”는 “회원” 간 또는 “회원”과 제3자 상호간에 “서비스”를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.'
        },
        {
          title:
            '"회사"는 무료로 제공되는 "서비스" 이용과 관련하여 관련법령에 특별한 규정이 없는 한 책임을 지지 않습니다.'
        },
        {
          title:
            '기타 "회사"의 고의 또는 과실이 아닌 사유로 "서비스" 장애가 발생하거나 "회원"의 정보 또는 자료에 손상이 있는 경우'
        }
      ]
    },
    // 21
    {
      title: '제 21조(준거법 및 재판관할)',
      paragraphs: [
        {
          title:
            '“회사”와 “회원”간 제기된 소송은 대한민국법을 준거법으로 합니다.'
        },

        {
          title:
            '“회사”와 “회원”간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.'
        }
      ]
    },
    {
      title: '개인정보처리방침',
      paragraphs: [
        {
          title:
            '이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 공지사항을 통하여 고지할 것입니다.',
          body: [
            { value: '- 공고일자 : 2024.07.10' },
            { value: '- 시행일자 : 2024.07.10' }
          ]
        }
      ]
    }
  ]
};

export const policy = {
  title: '개인정보처리방침',
  items: [
    {
      title: '제 1조(처리하는 개인정보의 항목 및 수집방법)',
      paragraphs: [
        {
          title: '회사는 다음의 개인정보 항목을 처리하고 있습니다.',
          body: [
            { value: ' 1. 문의하기' },

            {
              value: '  - 필수 항목 : 기업명, 이름, 휴대폰 번호, 이메일 주소',
              isChild: true
            },
            {
              value:
                '  - 선택 항목 : 도입 목적, 유입 경로, 지역, 월 예산, 인원 수, 창고 공간 제공 가능 여부',
              isChild: true
            },
            { value: '   2. 서비스소개서 신청' },
            {
              value: '  - 필수 항목 : 기업명, 이름, 휴대폰 번호, 이메일 주소',
              isChild: true
            },
            { value: '  - 선택 항목 : 없음', isChild: true },
            { value: '3. 마케팅 및 광고에의 활용' },
            {
              value: '  - 필수 항목 : 이름, 휴대폰 번호, 이메일 주소',
              isChild: true
            },
            { value: '  - 선택 항목 : 없음', isChild: true },
            {
              value:
                '   4. 서비스 이용 과정에서 아래와 같은 정보가 생성되어 수집될 수 있습니다.'
            },
            {
              value:
                '  - IP Address, 쿠키, 기기정보, 방문 일시, 서비스 이용 기록, 불량 이용 기록 등',
              isChild: true
            }
          ]
        }
      ]
    },
    {
      title: '제 2조(개인정보의 처리 목적)',
      paragraphs: [
        {
          title:
            ' 회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 개인정보보호법에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.',
          body: [
            { value: '1. 회원 관리' },

            {
              value:
                '   - 회원의 가입의사 확인, 이용자 본인 확인 및 식별, 채용 관련 안내, 공지사항 전달, 문의사항 또는 불만처리 등 회원관리를 목적으로 개인정보를 처리합니다.',
              isChild: true
            },

            { value: '   2. 서비스 제공' },
            {
              value:
                '  - 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금 결제·정산 등을 목적으로 개인정보를 처리합니다.',
              isChild: true
            },
            { value: '3. 마케팅 및 광고' },
            {
              value:
                ' - 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.',
              isChild: true
            }
          ]
        }
      ]
    },
    {
      title: '제 3조(개인정보의 처리 및 보유 기간)',
      paragraphs: [
        {
          title:
            '회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다.',
          body: [
            {
              value:
                ' 1.  전자상거래 등에서의 소비자 보호에 관한 법률에 따른 표시․광고, 계약내용 및 이행 등 거래에 관한 기록'
            },

            {
              value: '  - 표시․광고에 관한 기록 : 6개월',
              isChild: true
            },
            {
              value:
                '    - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년',
              isChild: true
            },
            {
              value: ' - 소비자 불만 또는 분쟁처리에 관한 기록 : 3년',
              isChild: true
            },

            {
              value: '   2. 통신비밀보호법 제 41조에 따른 통신사실확인자료 보관'
            },
            {
              value:
                '  - 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월',
              isChild: true
            },
            { value: '3. 부정/불량 이용자 보관' },
            {
              value: '  - 보관 기간 : 3년',
              isChild: true
            }
          ]
        }
      ]
    },
    {
      title: '제 4조(개인정보의 제 3자 제공)',
      paragraphs: [
        {
          title:
            '회사는 이용자의 개인정보를 제 2조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제 17조에 해당하는 경우에만 개인정보를 제 3자에게 제공합니다.단, 이용자의 동의가 있거나 법령에 의하여 정해진 절차에 따라 정보를 요청받은 경우는 예외로 하며, 이 경우 주의를 기울여 개인정보를 이용 및 제공할 수 있습니다.'
        }
      ]
    },
    {
      title: '제 5조(개인정보처리 위탁)',
      paragraphs: [
        {
          title:
            '회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리 업무를 위탁하고 있습니다.',
          body: [
            {
              value: policyTable,
              type: 'image'
            }
          ]
        },
        {
          title:
            '회사는 위탁계약 체결시 개인정보 보호법 제 26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.'
        },
        {
          title:
            '위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.'
        },
        {
          title: '회사는 다음과 같이 개인정보 국외이전을 하고 있습니다.',
          body: [
            {
              value: payment,
              type: 'image'
            }
          ]
        }
      ]
    },
    {
      title: '제 6조(정보주체와 법정대리인의 권리, 의무 및 그 행사 방법)',
      paragraphs: [
        {
          title:
            '이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.'
        },
        {
          title:
            '제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.'
        },
        {
          title:
            '제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.'
        },
        {
          title:
            '개인정보 열람 및 처리정지 요구는 개인정보보호법 제 35조 제 4항, 제 37조 제 2항에 의하여 정보주체의 권리가 제한될 수 있습니다.'
        },
        {
          title:
            '개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.'
        },
        {
          title:
            '회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.'
        }
      ]
    },
    {
      title: '제 7조(개인정보의 파기)',

      paragraphs: [
        {
          title:
            '  회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 개인정보 파기절차 및 방법은 다음과 같습니다.',
          body: [
            { value: '  1. 파기절차' },
            {
              value:
                '- 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.',
              isChild: true
            },
            { value: '  2. 파기방법' },
            {
              value:
                ' - 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.',
              isChild: true
            },
            {
              value:
                '- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.',
              isChild: true
            }
          ]
        }
      ]
    },
    {
      title: '제 8조(개인정보의 안전성 확보조치)',

      paragraphs: [
        {
          title:
            '    회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.',
          body: [
            { value: ' 1. 정기적인 자체 감사 실시' },

            {
              value:
                '    - 개인정보 취급 관련 안정성 확보를 위해 정기적으로 자체 감사를 실시하고 있습니다.',
              isChild: true
            },

            { value: '   2. 개인정보 취급 직원의 최소화 및 교육' },
            {
              value:
                '   - 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.',
              isChild: true
            },

            { value: '3. 해킹 등에 대비한 기술적 대책' },
            {
              value:
                '  - 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적·물리적으로 감시 및 차단하고 있습니다.',
              isChild: true
            },
            {
              value: '   4. 개인정보의 암호화'
            },
            {
              value:
                ' - 이용자의 개인정보인 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.',
              isChild: true
            },
            { value: '5. 접속기록의 보관 및 위변조 방지' },
            {
              value:
                '- 개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.',
              isChild: true
            },
            { value: '6. 개인정보에 대한 접근 제한' },
            {
              value:
                '- 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.',
              isChild: true
            },
            { value: '7. 비인가자에 대한 출입 통제' },
            {
              value:
                '   - 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.',
              isChild: true
            }
          ]
        }
      ]
    },
    {
      title: '제 9조(개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항)',

      paragraphs: [
        {
          title:
            '회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.'
        },
        {
          title:
            '     쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자들의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.',
          body: [
            { value: '1. 쿠키의 사용 목적' },
            {
              value:
                '  - 이용자들이 이용한 회사의 각 서비스와 이용형태 등을 분석하여 이용자에게 최적화된 맞춤형 정보를 제공하기 위하여 쿠키를 이용합니다.',
              isChild: true
            },
            { value: '2. 쿠키의 설치/운영 및 거부' },
            {
              value:
                ' - 웹브라우저 상단의 [도구 > 인터넷 옵션 > 개인정보] 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.',
              isChild: true
            },
            {
              value:
                '   - 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.',
              isChild: 'true'
            }
          ]
        }
      ]
    },
    {
      title: '제 10조(개인정보의 기술적/관리적 보호 대책)',

      paragraphs: [
        {
          title:
            '회사는 회원의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적·관리적 대책을 강구하고 있습니다.',
          body: [
            { value: '    1. 개인 아이디와 비밀번호 관리' },
            {
              value:
                '   - 회사는 회원의 개인정보를 보호하기 위하여 최선의 노력을 다하고 있습니다. 단, 회원 본인의 부주의나 회사의 고의 또는 중대한 과실이 아닌 사유로 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 회사는 일체의 책임을 지지 않습니다.',
              isChild: true
            },
            { value: '2. 개인정보취급자의 최소화 및 교육' },
            {
              value:
                '- 회사의 개인정보취급자는 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사 개인정보 처리방침의 준수를 항상 강조하고 있습니다.',
              isChild: true
            },

            { value: '3. 개인 아이디와 비밀번호 관리' },
            {
              value:
                '- 회사는 회원의 개인정보를 보호하기 위하여 최선의 노력을 다하고 있습니다. 단, 회원 본인의 부주의나 회사의 고의 또는 중대한 과실이 아닌 사유로 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 회사는 일체의 책임을 지지 않습니다.',
              isChild: true
            }
          ]
        }
      ]
    },
    {
      title: '제 11조(개인정보 보호책임자)',

      paragraphs: [
        {
          title:
            '회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.',
          body: [
            { value: '개인정보 보호책임자' },
            { value: '- 이름 : 문준환', isChild: true },
            { value: ' - 직책 : COO', isChild: true },
            { value: '- 직급 : 이사', isChild: true },
            { value: '- 연락처 : jhmoon@workershigh.com', isChild: true }
          ]
        },
        {
          title:
            '정보주체는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.'
        }
      ]
    },
    {
      title: '제 12조(정보주체의 권익 침해에 대한 구제 방법)',

      paragraphs: [
        {
          title:
            '정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.\n아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.',
          body: [
            { value: '   가. 개인정보 침해신고센터 (한국인터넷진흥원 운영)' },
            {
              value: '- 소관 업무 : 개인정보 침해 사실 신고, 상담 신청',
              isChild: true
            },
            { value: '- 홈페이지 : privacy.kisa.or.kr', isChild: true },
            { value: ' - 전화 : (국번 없이) 118', isChild: true },
            {
              value:
                '- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보 침해신고센터',
              isChild: true
            },
            { value: ' 나. 개인정보 분쟁조정위원회' },
            {
              value:
                '- 소관업무 : 개인정보 분쟁 조정신청, 집단분쟁 조정 (민사적 해결)',
              isChild: true
            },

            { value: '- 홈페이지 : www.kopico.go.kr', isChild: true },
            { value: '- 전화 : (국번 없이) 1833-6972', isChild: true },
            {
              value:
                '- 주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층',
              isChild: true
            },
            {
              value: '다. 대검찰청 사이버수사단 : 02-3280-3573 (www.spo.go.kr)'
            },
            {
              value:
                ' 라. 경찰청 사이버안전국 : (국번 없이) 182 (http://cyberbureau.police.go.kr)'
            }
          ]
        }
      ]
    },
    {
      title: '제 13조(기타)',

      paragraphs: [
        {
          title:
            '회사가 제공하는 서비스에 링크되어 있는 웹페이지들이 개인정보를 수집하는 행위에 대해서는 본 개인정보 처리방침이 적용되지 않음을 알려 드립니다.'
        }
      ]
    },
    {
      title: '개인정보처리방침',
      paragraphs: [
        {
          title:
            '이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 공지사항을 통하여 고지할 것입니다.',
          body: [
            { value: '- 공고일자 : 2024.07.10' },
            { value: '- 시행일자 : 2024.07.10' }
          ]
        }
      ]
    }
  ]
};
