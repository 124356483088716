import { Checkbox, styled } from '@mui/material';

const CustomCheck = styled('span')(({ theme }) => ({
  borderRadius: '1px',
  border: '1px solid #DEE2E6', // Changed border to '2px solid grey'
  width: 20,
  height: 20,
  backgroundColor: '#FFFFFF'

  // Add stroke customization for the unchecked icon
}));

const BpChecked = styled(CustomCheck)({
  backgroundColor: '#244EE2',
  border: 0,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    position: 'absolute',
    top: '50%', // Center the pseudo-element vertically
    left: '50%', // Center the pseudo-element horizontally
    transform: 'translate(-50%, -50%)',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: '#244EE2'
  }
});
export function MyCheckbox(props) {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpChecked />}
      icon={<CustomCheck />}
      {...props}
    />
  );
}
