/**
 * input the "fileName" from api, ex: 1678963616217-this-item-might-not-exist2.svg
 */
export const isImage = (filename) => {
  if (!filename || typeof filename !== 'string') return false;
  const supportedImageExtensitons = ['gif', 'jpg', 'png', 'jpeg', 'svg'];
  const splitedFilename = filename.split('.');
  return supportedImageExtensitons.includes(
    splitedFilename[splitedFilename.length - 1].toLocaleLowerCase()
  );
};

export const isKoreanPhoneNumber = (string) => {
  const koPhoneNumPattern =
    /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?/;
  return koPhoneNumPattern.test(string);
};

export const isEmail = (string) => {
  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/;
  return emailPattern.test(string);
};

export const isValidDate = (date) => {
  // eslint-disable-next-line no-restricted-globals
  return date instanceof Date && !isNaN(date);
};

export const is11Number = (num) => {
  return num?.length === 11 && /^\d+$/.test(num);
};
export const isValidKoreanPhoneNumber = (phoneNumber) => {
  const mobileRegex = /^01[016789][0-9]{7,8}$/;

  const regionalRegex = /^(0[2-9][0-9]?)[0-9]{7,8}$/;

  return mobileRegex.test(phoneNumber) || regionalRegex.test(phoneNumber);
};
