import React, { useEffect, useState } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import arrowUpBlue from 'assets/svg/arrow-up-blue.svg';
import './ScrollTop.css';
import useResponsive from '@hooks/useResponsive';

const BackToTop = () => {
  const [visible, setVisible] = useState(false);
  const [strokeDashoffset, setStrokeDashoffset] = useState(0);
  const isSm = useResponsive('down', 1024);
  useEffect(() => {
    const scrollPath = document.querySelector('.scroll-circle path');
    const pathLength = scrollPath.getTotalLength();

    scrollPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
    scrollPath.style.strokeDashoffset = pathLength;

    const handleScroll = () => {
      const scrollTotal = window.scrollY;
      const height = document.documentElement.scrollHeight - window.innerHeight;
      const scrollTotalHeight =
        pathLength - (scrollTotal * pathLength) / height;

      setStrokeDashoffset(scrollTotalHeight);

      if (scrollTotal > 50) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Stack
      sx={{
        position: 'fixed',
        bottom: { md: 130, xs: 100 },
        right: { xs: 15, md: 29 },
        zIndex: 99,
        display: visible ? 'block' : 'none',
        boxShadow: '0px 0px 3px #244EE2',
        cursor: 'pointer',
        borderRadius: '50%',
        overflow: 'visible'
      }}
      onClick={() => scrollToTop()}
    >
      <svg
        className="scroll-circle"
        width="58px" // Adjust this value to control the outer border size
        height="58px"
        viewBox="-1 -1 105 105"
        style={{
          position: 'absolute',
          top: '-1px', // Adjust this value to position the circle around the button
          left: '-1px',
          zIndex: 10
        }}
      >
        <path
          d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          style={{
            fill: 'none',
            stroke: '#244EE2',
            strokeWidth: '5px',
            strokeDashoffset,
            transition: 'stroke-dashoffset 10ms linear'
          }}
        />
      </svg>
      <IconButton
        onClick={scrollToTop}
        sx={{
          width: { md: 54, xs: 45 },
          height: { md: 54, xs: 45 },
          borderRadius: '50%',
          backgroundColor: { md: '#FFFFFF', xs: 'transparent' },

          display: 'flex',
          flexDirection: 'column',
          transition: 'transform 0.4s ease-in-out',
          transform: visible ? 'translateY(0)' : 'translateY(15px)'
        }}
      >
        {isSm ? (
          <img src={arrowUpBlue} alt="top" />
        ) : (
          <KeyboardArrowUpIcon
            sx={{
              fontSize: { xs: 15, md: 20 },
              color: '#1B2872'
            }}
          />
        )}
        {!isSm && (
          <Typography
            sx={{
              fontWeight: 500,
              fontFamily: 'Pretendard',
              color: '#1B2872',
              fontSize: { xs: 11, md: 14 },
              textAlign: 'center',
              mt: { md: '-8px', xs: '-4px' }
            }}
          >
            Top
          </Typography>
        )}
      </IconButton>
    </Stack>
  );
};

export default BackToTop;
