import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MyLoadingButton from '@components/MyLoadingButton';

export default function MyDialog({
  open,
  setOpen,
  title = 'Your Title',
  children,
  onOk,
  insertHtml,
  onCancel,
  okTitle,
  cancelTitle,
  hasCancelButton,
  isAlert = false,
  hasCloseButton,
  hideDialogButton = false,
  sx,
  subTitle,
  disabled,
  onClose,
  ...rest
}) {
  const { t } = useTranslation(null, { keyPrefix: 'button' });

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ zIndex: 1000 }}
      PaperProps={{
        sx: {
          zIndex: 1000,
          borderRadius: 0,
          width: isAlert ? '280px' : 'unset',
          minWidth: isAlert ? '200px' : '650px',
          maxWidth: isAlert ? '480px' : '90vw',
          p: isAlert ? '30px 25px 20px' : '0',
          ...sx,
          overflowY: 'auto'
        }
      }}
      slotProps={{
        backdrop: { sx: { bgcolor: '#343434', opacity: '0.6 !important' } }
      }}
      {...rest}
    >
      <DialogTitle
        sx={{
          color: '#212121',
          fontSize: isAlert ? '15px !important' : '24px !important',
          textAlign: 'left',
          letterSpacing: '-0.72px',
          fontWeight: 700,
          px: 4,
          pt: 4,
          whiteSpace: isAlert ? 'wrap' : 'nowrap',
          overflow: 'hidden',
          '$ span': { color: '#244EE2' }
        }}
      >
        {title}
        {!isAlert && hasCloseButton ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 32,
              top: 30,
              color: 'black'
            }}
          >
            <CloseIcon sx={{ color: 'black' }} />
          </IconButton>
        ) : null}
        {subTitle && typeof subTitle === 'function' ? (
          subTitle
        ) : (
          <Typography
            sx={{
              fontSize: 14,
              color: '#919193',
              fontWeight: 500,
              letterSpacing: '-0.42px'
            }}
          >
            {subTitle}
          </Typography>
        )}
      </DialogTitle>
      {children && (
        <DialogContent id="form-container" sx={{ px: 4, pb: 4 }}>
          {children}
        </DialogContent>
      )}
      {!hideDialogButton && (
        <DialogActions sx={{ justifyContent: 'center', mt: '30px', p: 0 }}>
          {hasCancelButton && (
            <Button
              autoFocus
              onClick={onCancel || handleClose}
              variant="outlined"
              sx={{
                height: '44px',
                minWidth: '110px',
                flex: isAlert ? 1 : 'unset'
              }}
            >
              {cancelTitle || t('cancel')}
            </Button>
          )}
          <MyLoadingButton
            onClick={onOk || handleClose}
            variant="contained"
            sx={{
              height: '44px',
              minWidth: '110px',
              flex: isAlert ? 1 : 'unset'
            }}
            disabled={disabled}
          >
            {okTitle || t('check')}
          </MyLoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
